<template>
  <div class="area-details-container">

    <OverlayImage
      overImageY = "0"
      overImageFolder = "areas"
      overImageLink = "woods"
      overImageImg = "AreaMaderas.jpg"
      overImageTitle = "Área Maderas"
    />

    <div v-for="madera in maderas" :key="madera.title">
      <MobileArea 
        :maTitle = madera.title
        :maText = madera.text
        :maButtons = madera.buttons
      />  
    </div>

  </div>
</template>

<script>
import MobileArea from '@/components/MobileArea.vue'
import OverlayImage from '@/components/OverlayImage.vue'
export default {
  name: 'AreaDetails',
  components: {
    MobileArea,
    OverlayImage
  },
  data() {
    return {
      maderas: []
    }
  },
  created() {
    this.maderas = this.$store.state.maderas
  }
}
</script>

<style scoped>
.area-details-container {
  margin-top: 80px;
  margin-bottom: 40px;
}

</style>