<template>

  <div class="header-wrapper">
    <OverlayHeader 
    headerFolder = "services"
    headerImg = "services.jpg"
    headerTitle = "Servicios Tapel"
    headerText = ""  
    />
  </div>

  <MobileArticle
    articleTitle = "Servicio Técnico"
    articleSubtitle = "compromiso 24/7"
    articleFolder = "services"
    articleImg = "ServicioTecnico.jpg"
    articleText = "Nuestro servicio 24/7 se caracteriza por tener personal especialista y 
    calificado para mantener equipos que aseguren confiablemente la continuidad de la 
    operación en planta de nuestros clientes. Nuestro principal foco es trabajar de la mano 
    con nuestros clientes, ya sea, con productos –equipos- servicios y también para 
    apoyarlos en la mejora de sus procesos, entendiendo que optimizar los recursos es clave 
    para el éxito de las operaciones. Para poder cumplir con lo anterior aseguramos visitas 
    regulares a las instalaciones, con el fin de entender a fondo su proceso y adaptar 
    nuestro servicio a la medida que el cliente necesite.
    Trabajamos arduamente en afinar nuestros programas de mantención enfocándonos 
    principalmente a la mantención preventiva, también es importante destacar las 
    capacitaciones dictadas a los operadores que operan nuestros equipos y /o aplican 
    nuestros productos, estas se realizan en sala y en terreno logrando así transmitir 
    conocimiento y experiencias que aseguren la operación impecable que nuestros clientes 
    necesitan."
    :articleLeft = "false"
  />
  
  <div class="negative-separator"></div>
  
  <MobileArticle
    articleTitle = "Ingenieros de Procesos"
    articleSubtitle = "Profesionales a tu servicio"
    articleFolder = "services"
    articleImg = "Ingenieria.jpg"
    articleText = "Tapel Willamette cuenta con un innovador servicio de Ingeniería de Procesos. Este consiste 
    en realizar visitas periódicas a nuestros clientes, donde primordialmente se realiza el monitoreo del buen 
    uso de nuestros productos, identificando las desviaciones que puedan existir y sugiriendo las medidas 
    correctivas para cada caso.  De esta manera, se genera un contacto directo con los operadores, obteniendo 
    in situ la retroalimentación del comportamiento y rendimiento de nuestros productos, aclarando dudas y 
    aportando con ideas que puedan contribuir a la eficiencia de los procesos, con el fin de hacer más 
    rentables sus negocios. Se coordinan también capacitaciones cuando se requieren, con el fin de entregar 
    las herramientas necesarias a nuestros usuarios para el óptimo desempeño de nuestros productos y 
    tecnologías. Nuestros Ingenieros de Proceso también aclaran todas las dudas y consultas que puedan existir 
    relacionadas a nuestros productos."
    articleTextHl = ""
    :articleLeft = "true"
  />

  <div class="negative-separator"></div>

  <MobileArticle
    articleTitle = "Laboratorio e Investigación"
    articleSubtitle = "Desarrollo Tecnológico"
    articleFolder = "services"
    articleImg = "Investigacion.jpg"
    articleText = "Tapel Willamette cuenta con un laboratorio de Investigación y Desarrollo 
    con tecnología de punta que está conformado por profesionales con enfoque creativo y de 
    gran capacidad analítica que logran plasmar en cada desarrollo la calidad y 
    sustentabilidad que el mercado actual necesita. Nuestras modernas instalaciones nos 
    permiten desarrollar y probar productos según las necesidades de nuestros clientes más 
    exigentes, atendiendo así de manera eficiente y eficaz cada uno de los proyectos. Tapel 
    Willamette considera que la ciencia está al servicio de la industria, por lo que 
    trabajamos codo a codo con nuestra casa matriz en Estados Unidos, para poner a 
    disposición de nuestros clientes las mentes más brillantes dentro del sector, entregando 
    soluciones integrales a la medida de cada situación."
    :articleLeft = "false"
  />

</template>

<script>
// import OverlayImage from '@/components/OverlayImage.vue'
import OverlayHeader from '@/components/OverlayHeader.vue'
import MobileArticle from '@/components/MobileArticle.vue'
export default {
  name: 'Services',
  components: {
    OverlayHeader,
    MobileArticle
  }
}
</script>

<style scoped>

.header-wrapper {
  margin-top: 80px;
}
.negative-separator {
  margin-bottom: -10px;
}

</style>