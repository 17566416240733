<template>

  <div class="header-wrapper">
    <OverlayHeader 
      headerFolder = "company"
      headerImg = "company.jpg"
      headerTitle = "Nuestra Empresa"
      headerText = "Comprometidos con nuestros clientes, la comunidad y el medio ambiente."  
    />
  </div>

  <OurCompany 
    :notButton = "false"
  />

  <OverlayText
    overlayTextColor = "burgundy"
    overlayTextFolder = "company"
    overlayTextImg = "Team2.jpg"
    overlayTextTwo = "&#34;Nos asociamos a través del servicio, la innovación y la integridad&#34;"  
  />

  
  <MobileArticle
    articleTitle = "Nuestro Equipo"
    articleFolder = "company"
    articleImg = "Team.jpg"
    articleText = "Queremos apoyar su negocio en todas las maneras posibles y asegurar 
    que contribuimos a su prosperidad y éxito."
    articleTextHl = ""
    :articleLeft = "true"
  />

  <div class="negative-separator"></div>

  <MobileArticle
    articleTitle = "Historia"
    articleFolder = "company"
    articleImg = "Historia.jpg"
    articleText = "Tapel Willamette Inc. S.A forma parte de la corporación WIllamette 
    Valley Company, multinacional privada de vanguardia que produce y distribuye una amplia 
    variedad de productos y servicios a medida en Estados Unidos, Europa, Canadá, Asia y 
    desde el año 2000 a toda Sudamérica a través de nuestra planta ubicada en Coronel, 
    región del Biobío. Si bien nuestros orígenes se encuentran ligados a la industria de la 
    madera desde 1952, en la actualidad nuestra experiencia, conocimiento y soluciones 
    abarcan desde revestimientos innovadores, fillers, adhesivos, robótica, repuestos, 
    hasta ingeniería y mucho más."
    articleTextHl = ""
    :articleLeft = "false"
  />

  <!-- <div class="team-img">
    <img :src="require(`../assets/company/Team.jpg`)">
  </div> -->

  <div class="negative-separator"></div>

  <MobileArticle
    articleTitle = "Certificaciones y Política de Calidad"
    articleFolder = "company"
    articleImg = "LabCalidadCert.jpg"
    articleText = "Nuestra filosofía de excelencia nos ha llevado a buscar y desarrollar 
    herramientas que nos permitan satisfacer a cabalidad las necesidades de nuestros 
    clientes sin dejar de lado las necesidades del medio en que estamos insertos. 
    Es por ello que nos enorgullece contar con certificaciones internacionales que avalan 
    nuestro compromiso de asociarnos a través del servicio, la innovación y la integridad."
    :articleTextHl = certInfo
    :articleLeft = "true"
  />
  <!-- articleTextHl = "Nuestro Sistema de Gestión de Calidad ha sido certificado bajo el 
    estándar ISO 9001:2015 desde el 13 de diciembre de 2019 hasta el 22 de noviembre de 2022.
    Nuestra Compañía cuenta con Verificación en Conducta Responsable desde el 18 de junio de 
    2019 y hasta el 18 de junio de 2022." -->
  
  
  
  <div class="cert">
    <div class="declaration">
      <a href="./politica_de_calidad.pdf" target="_blank" rel="noopener noreferrer">
        Política de Calidad
      </a>
    </div>
    <div class="declaration">
      <a href="./politica_sso.pdf" target="_blank" rel="noopener noreferrer">
        Política SSO
      </a>
    </div>
    <div class="cert-img">
      <a href="./iso_9001.pdf" target="_blank" rel="noopener noreferrer">
        <img src="../assets/company/Cert1.jpg" alt="ISO 9001">
      </a>
    </div>
    <div class="cert-img">
      <a href="./responsible_care.pdf" target="_blank" rel="noopener noreferrer">
        <img src="../assets/company/Cert2.jpg" alt="Responsible Care">
      </a>
    </div>
  </div>

</template>

<script>
import OverlayHeader from '@/components/OverlayHeader.vue'
import OverlayText from '@/components/OverlayText.vue'
import OurCompany from '@/components/OurCompany.vue'
import MobileArticle from '@/components/MobileArticle.vue'

export default {
  name: 'Company',
  components: {
    OverlayHeader,
    OverlayText,
    OurCompany,
    MobileArticle
  },
  data() {
    return {
      certInfo: ''
    }
  },
  created() {
    this.certInfo = this.$store.state.certInfoTest
  }
}
</script>

<style scoped>

.declaration a {
    color: #831F3B;
}

.cert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 30px 20px;
  width: 100%;
}
.cert-img {
  width: 25%;
  
}
.cert-img img {
  width: 75%;
  height: auto;
}
.header-wrapper {
  margin-top: 80px;
}
.negative-separator {
  margin-bottom: -30px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .cert {
    /* padding: 30px 15vw 40px 15vw; */
    /** */
    padding: 0 0 4vh 15vw;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
  .cert-img {
    width: 25%;
    height: auto;
  }
  .negative-separator {
    display: none;
  }
}
/* @media screen and (min-width: 1600px) { */
@media (min-width: 1600px) and (max-width: 2559px) {
  .cert {
    /* padding: 40px 15vw 50px 15vw; */
    /** */
    margin-top: -20px;
    padding: 0 0 4vh 15vw;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
  .cert-img {
    width: 20%;
    height: auto;
  }
  .negative-separator {
    display: none;
  }
}

@media screen and (min-width: 2560px) {
  .cert {
    /* padding: 40px 15vw 50px 15vw; */
    /** */
    margin-top: -8vw;
    padding: 0 0 4vh 15vw;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
  .cert-img {
    width: 20%;
    height: auto;
  }
  .negative-separator {
    display: none;
  }
}

</style>