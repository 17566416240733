<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->

    <Menu />
    <!-- <router-view/> -->
    <router-view :key="$route.fullPath" />
    <Footer />

  <!-- <router-view/> -->
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Menu,
    Footer
  }
}
</script>

<style>
* {
  /* reset - verificar */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-family: 'Lato';
  src: local('Lato'), url('./assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-family: 'Inter';
  src: local('Inter'), url('./assets/fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
}

/* verificar */
html, body {
  font-family: 'Inter', sans-serif;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}
</style>
