<template>
  <div class="vs-container" :style="containerStyle">
    <div class="vs-text" :style="titleStyle">
      {{title}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerticalSubtitle',
  props: {
    title: String,
    color: String
  },
  data() {
    return {
      containerStyle: {},
      titleStyle: {}
    }
  },
  mounted() {
    if(this.$props.color == 'white') {
      this.containerStyle = { 'border-top': '2px solid #831F3B' }
      this.titleStyle = { 'color': '#9C9A9A' }
    } else {
      this.containerStyle = { 'border-top': '2px solid white' }
      this.titleStyle = { 'color': 'white' }
    }
  }
}
</script>

<style scoped>
.vs-container {
  width: 40px;
  position: absolute;
  top: 40px;
  left: 0;
  display: none;
}
.vs-text {
  margin-top: 10px;
  margin-left: 30px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-transform: rotate(90deg);   
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .vs-container {
    display: block;
  }
}
@media screen and (min-width: 1600px) {
  .vs-container {
    display: block;
  }
}

</style>