<template>
   <div class="header-container">
    <div class="header-main">
      <img :src="require(`../assets/${headerFolder}/${headerImg}`)">
      <div class="header-overlay"
      :style=" headerColor ? {'background-color': 'rgba(131, 31, 59, 0.7)', 'color':'white'} : {'background-color': 'rgba(0, 0, 0, 0.4)', 'color':'white'}">
        <div class="header-main-title"> {{headerTitle}} </div>
        <div class="header-main-text"> {{headerText}} </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlayHeader',
  props: {
    headerFolder: String,
    headerImg: String,
    headerColor: String,
    headerTitle: String,
    headerText: String
  }
}
</script>

<style scoped>
.header-main {
  width: 100%;
  position: relative;
}
.header-details {
  padding: 10px 20px 30px 20px;
}
.header-main img {
  display: block;
  width: 100%;
  /* height: auto; */
  height: 50vw;
  object-fit: cover;
}
.header-overlay {
  position: absolute;
  top: 0;
  /* background-color: rgba(0, 0, 0, 0.7); */
  /* background: linear-gradient(to right, rgba(0,0,0,0.7), rgba(0,0,0,0)); */
  
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%);

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 20px 0;
}
.header-main-title {
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.header-main-text {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
  padding: 0 26px 0 26px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .header-main img {
    width: 100%;
    max-height: 40vh;
    object-fit: cover;
    object-position: 50% 44%;
  }
  .header-main-title {
    font-size: 20px;
    font-weight: 500;
  }
  .header-main-text {
    font-size: 16px;
    padding: 0;
  }
  .header-overlay {
    align-items: flex-start;
    padding-left: 15vw;
  }
}

@media screen and (min-width: 1600px) {
  .header-main img {
    width: 100%;
    max-height: 40vh;
    object-fit: cover;
    object-position: 50% 44%;
  }
  .header-main-title {
    font-size: 22px;
    font-weight: 500;
  }
  .header-main-text {
    font-size: 18px;
    padding: 0;
  }
  .header-overlay {
    align-items: flex-start;
    padding-left: 15vw;
  }
}

</style>