<template>
  <div class="news-details-wrapper">
    
    <div class="news-header">
      <div class="news-header-title">
        Noticias Comunidad Tapel
      </div>
      <div class="news-header-separator"></div>
    </div>

    <div class="news-details-lg">
      <div class="news-details-lg-left">
        <MobileNews
          :mnImage = news.image
          :mnTitle = news.title
          :mnSubTitle = news.subTitle
          :mnText = news.text
          :mnDetailsAuthor = news.author
          :mnDetailsDate = news.date
        />
      </div>
      <div class="news-details-lg-separator"></div>
      <div class="news-details-lg-right">
        <!-- <NewsMini /> -->
        <div class="secondary-news" @click="$router.push(`/news/${latestNews[4].id}`)">
          <img :src="require(`../assets/news/${latestNews[4].image[0]}`)">
          <div class="secondary-news-overlay">
            {{latestNews[4].title}}
          </div>
        </div>
        <div class="secondary-news" @click="$router.push(`/news/${latestNews[3].id}`)">
          <img :src="require(`../assets/news/${latestNews[3].image[0]}`)">
          <div class="secondary-news-overlay">
            {{latestNews[3].title}}
          </div>
        </div>
        <div class="secondary-news" @click="$router.push(`/news/${latestNews[2].id}`)">
          <img :src="require(`../assets/news/${latestNews[2].image[0]}`)">
          <div class="secondary-news-overlay">
            {{latestNews[2].title}}
          </div>
        </div>
        <div class="secondary-news" @click="$router.push(`/news/${latestNews[1].id}`)">
          <img :src="require(`../assets/news/${latestNews[1].image[0]}`)">
          <div class="secondary-news-overlay">
            {{latestNews[1].title}}
          </div>
        </div>

      </div>
    </div>
    <div class="news-details">
      <MobileNews
          :mnImage = news.image
          :mnTitle = news.title
          :mnSubTitle = news.subTitle
          :mnText = news.text
          :mnDetailsAuthor = news.author
          :mnDetailsDate = news.date
        />
      <NewsMini />
    </div>
    
  </div>
</template>

<script>
import MobileNews from '@/components/MobileNews.vue'
import NewsMini from '@/components/NewsMini.vue'
export default {
  name: 'NewsDetail',
  components: {
    MobileNews,
    NewsMini
  },
  created() {
    this.news = this.$store.getters.newsById(this.$route.params.newsid)
    if(this.$route.params.newsid) {
      this.latestNews = this.$store.getters.newsOthersWoId(this.$route.params.newsid)
    } else {
      this.latestNews = this.$store.getters.newsOthers
    }
  },
  data() {
    return {
      news: {},
      latestNews: []
    }
  }
}
</script>

<style scoped>
  .news-header {
    margin-top: 80px;
    background: #831F3B;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .news-header-title {
    color: white;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
  }
  .news-header-separator {
    margin-top: 2px;
    border-bottom: 1px solid white;
    width: calc(100% - 40px);
  }
  /** */
  .news-details-lg {
    display: none;
  }
  /** */
  @media (min-width: 1200px) and (max-width: 1599px) {
    /** */
    .news-details {
      display: none;
    }
    .news-details-wrapper {
      padding-bottom: 20px;
    }
    .news-header-title {
      font-weight: 500;
      font-size: 20px;
    }
    .news-header-separator {
      margin-top: 6px;
      border-bottom: 2px solid white;
      width: 70%;
    }
    .news-details-lg {
      display: flex;
      padding: 20px 15vw 20px 15vw;
      justify-content: space-between;
      /* align-items: center; */
    }
    .news-details-lg-left {
      width: 65%;
      order: 1;
      position: relative;
      cursor: pointer;
    } 
    .news-details-lg-separator {
      width: 3%;
      order: 2;
    } 
    .news-details-lg-right {
      width: 32%;
      order: 3;
      /** */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 34vw;
    }
    /** */
    .secondary-news {
      width: 100%;
      height: 33vh;
      position: relative;
      cursor: pointer;
      margin-bottom: 3vh;
    }
    .secondary-news img {
      width: 100%;
      /* height: auto; */
      max-height: 33vh;
      object-fit: cover;
      object-position: 50% 50%;
    }
    .secondary-news-overlay {
      position: absolute;
      bottom: 0px; /** ?? */
      width: 100%;
      height: 30%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      color: white;
      padding: 0 2% 0 2%;
    }
  }
  @media screen and (min-width: 1600px) {
    /** */
    .news-details {
      display: none;
    }
    .news-details-wrapper {
      padding-bottom: 20px;
    }
    .news-header-title {
      font-weight: 600;
      font-size: 22px;
    }
    .news-header-separator {
      margin-top: 8px;
      border-bottom: 2px solid white;
      width: 70%;
    }
    .news-details-lg {
      display: flex;
      padding: 20px 15vw 60px 15vw; /** */
      justify-content: space-between;
      /* align-items: center; */
    }
    .news-details-lg-left {
      width: 65%;
      order: 1;
      position: relative;
      cursor: pointer;
      /** */
      /* max-height: 100vw; */
    } 
    .news-details-lg-separator {
      width: 3%;
      order: 2;
    } 
    .news-details-lg-right {
      width: 32%;
      order: 3;
      /** */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 34vw;
      /** wtf */
      margin-bottom: 62vh;
    }
    /** */
    .secondary-news {
      width: 100%;
      height: 33vh;
      position: relative;
      cursor: pointer;
      margin-bottom: 3vh;
    }
    .secondary-news img {
      width: 100%;
      /* height: auto; */
      max-height: 33vh;
      object-fit: cover;
      object-position: 50% 0%;
    }
    .secondary-news-overlay {
      position: absolute;
      bottom: 0px; /** ?? */
      width: 100%;
      height: 30%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      color: white;
      padding: 0 2% 0 2%;
    }
  }
</style>