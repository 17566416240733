<template>
  <div class="article-container">

    <div class="article-lg" :style="articleLeft ? {'order': '0', 'margin-right': '30px'} : {'order': '1', 'margin-left': '30px'}">
      <div class="article-title"> {{articleTitle}} </div>
      <div v-if="articleSubtitle" class="article-subtitle">
        {{articleSubtitle}}
      </div>
      <div v-else class="article-separator">
        <div class="as1"></div>
        <div class="as2"></div>
      </div>
      <div class="article-text">
        {{articleText}}
      </div>

      <div class="article-text-highlight" >
        <span v-html="athlTest"></span>
        <!-- {{articleTextHl}} -->
      </div>
     
    </div>
    <div class="article-img-lg">
      <img :src="require(`../assets/${articleFolder}/${articleImg}`)">
    </div>



    <div class="article-sm">

      <div class="article-title"> {{articleTitle}} </div>
      <div v-if="articleSubtitle" class="article-subtitle">
        {{articleSubtitle}}
      </div>
      <div v-else class="article-separator">
        <div class="as1"></div>
        <div class="as2"></div>
      </div>
      <div class="article-img">
        <img :src="require(`../assets/${articleFolder}/${articleImg}`)">
      </div>
      <div class="article-text">
        {{articleText}}
      </div>
      <div class="article-text-highlight" >
        <span v-html="athlTest"></span>
        <!-- {{articleTextHl}} -->
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'MobileArticle',
  props: {
    articleTitle: String,
    articleSubtitle: String,
    articleFolder: String,
    articleImg: String,
    articleText: String,
    articleTextHl: String,
    articleLeft: Boolean
  },
  data() {
    return { //TODO: fix
      athlTest: this.articleTextHl
    }
  }
}
</script>

<style scoped>

.article-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px 10px 20px;
}
.article-title {
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-transform: uppercase;
}
.article-subtitle {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #831F3B;
  margin-bottom: 20px;
}
.article-separator {
  width: 100%;
  display: flex;
  margin: 8px 0 20px 0;
}
.article-separator .as1 {
  border-bottom: 2px #831F3B solid;
  width: 50%;
}
.article-separator .as2 {
  border-top: 1px #9C9A9A solid;
  width: 50%;
}
.article-img {
  width: 100%;
  position: relative;
}
.article-img img {
  width: 100%;
  height: auto;
}
.article-text {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: justify;
  margin-top: 20px;
}
.article-text-highlight {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: justify;
  color: #831F3B;
  margin-top: 20px;
}
/** */
.article-lg, .article-img-lg  {
  display: none;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .article-sm {
    display: none;
  }
  .article-lg {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 22.6vw;
  }
  .article-container {
    padding: 40px 15vw 20px 15vw;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .article-title {
    font-size: 20px;
    font-weight: 500;
    text-align: start;
  }
  .article-subtitle {
    font-size: 16px;
    margin: 6px 0 20px 0;
    text-align: start;
  }
  .article-img-lg {
    display: flex;
    width: 50%;
    height: auto;
  }
  .article-img-lg img {
    width: 100%;
    max-height: 40vw;
    object-fit: cover;
    object-position: 50% 100%;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
  }
  .article-text {
    font-size: 16px;
    text-align: justify;
  }
  .article-text-highlight {
    font-size: 16px;
    text-align: justify;
  }
  .article-separator {
    margin: 12px 0 22px 0;
  }
}

@media screen and (min-width: 1600px) {

  .article-sm {
    display: none;
  }
  .article-lg {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 22.8vw;
  }
  .article-container {
    padding: 40px 15vw 20px 15vw;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .article-title {
    font-size: 22px;
    font-weight: 500;
    text-align: start;
  }
  .article-subtitle {
    font-size: 18px;
    margin: 6px 0 20px 0;
    text-align: start;
  }
  .article-img-lg {
    display: flex;
    width: 50%;
    height: auto;
  }
  .article-img-lg img {
    width: 100%;
    max-height: 40vw;
    object-fit: cover;
    object-position: 50% 100%;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
  }
  .article-text {
    font-size: 18px;
    text-align: justify;
  }
  .article-text-highlight {
    font-size: 18px;
    text-align: justify;
  }
  .article-separator {
    margin: 14px 0 22px 0;
  }
}
</style>