<template>
  <Carousel :settings="settings" :breakpoints="breakpoints">
   
    <Slide v-for="image in images" :key="image.id">
      <img :src="getImage(image.url)"/>
    </Slide>

    <template #addons>
      <Navigation />
      <Pagination />
    </template>

  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Pagination, Navigation, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'Basic',
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination
  },
  props: {
    imgSrc: Array
  },
  data() {
    return {
      images: [],
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        // 320px and up
        320: {
          itemsToShow: 0.5,
          snapAlign: 'center',
        }
      }
    }
  },
  mounted() {
    for(const [idx, img] of this.imgSrc.entries()){
      this.images.push({
        id: idx,
        url: img
      })
    }
  },
  methods: {
    getImage(imagePath) {
      return require('../assets/news/'+ imagePath);
    }
  }
});
</script>

<style>
.carousel__pagination-button {
  background: #8f354e;
}
.carousel__pagination-button--active {
  background: #000000;
}
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color:  var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 0px 10px 10px 10px;

}

.carousel__prev,
.carousel__next {
  background: #831f3b;
  box-sizing: content-box;
  border: 5px solid white;
}

</style>