<template>
  <div class="footer">

    <div class="contact-info">
      <div class="footer-logo" @click="$router.push('/')">
        <img src="../assets/TapelLogoWhite.png" alt="">
      </div>
      <div class="footer-text">
        <!-- <p> Tapel Willamette 2021 &copy; </p>  -->
        <p> Avenida Estero la Posada #3625, </p>
        <p> Parque Industrial Coronel </p>
        <p> Coronel, Región del Bío Bío. </p>
        <p> Casilla Nº 2922 Concepción </p>
        <p> 41 292-8100 </p>
        <p> 41 292-8150 </p>
        <p> ventas@tapel.cl </p>
        
      </div>
      <div class="footer-icons">
        <a :href="`https://www.youtube.com/channel/UCnFtE-bK75P9TcIK9qmX7Mg`" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-youtube fa-2x"></i>
        </a>
        <a :href="`https://www.instagram.com/tapelchile/?hl=es-la`" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-instagram fa-2x"></i>
        </a>
        <a :href="`https://www.linkedin.com/in/tapel-willamette-038337223`" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-linkedin fa-2x"></i>
        </a>
      </div>
      <div class="rights"> 
        <p> Tapel Willamette Inc. S.A.®  </p>
      </div>
    </div>

    <div class="info">
      <p> Empresa </p>
      <span> 
        <router-link to="/company"> Historia </router-link>
      </span>
      <span> 
        <router-link to="/company"> Misión </router-link>
      </span>
      <span> 
        <router-link to="/company"> Certificaciones </router-link>
      </span>
      <span> 
        <router-link to="/news"> Noticias </router-link>
      </span>
      <span> 
        <router-link to="/contact"> Contacto </router-link>
      </span>
    </div>

    <div class="info">
      <p> Áreas </p>
      <span> 
        <router-link to="/areas/woods"> Área Maderas </router-link>
      </span>
      <span> 
        <router-link to="/areas/environmental"> Área Ambiental </router-link>
      </span>
      <span> 
        <router-link to="/areas/industrial"> Área Industrial </router-link>
      </span>
    </div>

    <div class="info">
      <p> Servicios </p>
      <span> 
        <router-link to="/services"> Servicio Técnico </router-link>
      </span>
      <span> 
        <router-link to="/services"> Laboratorio I + D </router-link>
      </span>
      <span>
        <router-link to="/services"> Ingeniería de Procesos </router-link>
      </span>
    </div>

  </div>


</template>

<script>
export default {

}
</script>

<style scoped>
  .footer {
    background: #831F3B;
    min-height: 300px;
    color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    justify-content: center;
    align-items: center;
    font-family: 'Lato', sans-serif;
    /** */
    position: relative;
  }
  .footer-logo {
    width: 218.9px;
    height: 39.6px;
    margin-bottom: 20px;
  }
  .footer-logo img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
  .footer-icons {
    padding-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-icons a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
  }
  .footer-icons i {
    margin-right: 12px;
  }
  .info {
    display: none;
  }
  .rights {
    margin-top: 10%;
  }
  /* .rights {
    position: absolute;
    bottom: 0;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  @media (min-width: 1200px) and (max-width: 1599px) {
    .footer-icons {
      justify-content: flex-start;
    }
    .contact-info {
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .footer-text {
      text-align: start;
    }
    .info {
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .info p {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    .info span {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      margin-bottom: 10px;
    }
    .footer {
      padding: 0 15vw 0 15vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .info a {
      text-decoration: none;
      color: white;
    }
  }
  @media screen and (min-width: 1600px) {
    .footer-icons {
      justify-content: flex-start;
    }
    .contact-info {
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .footer-text {
      text-align: start;
    }
    .info {
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .info p {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    .info span {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      margin-bottom: 10px;
    }
    .footer {
      padding: 0 15vw 0 15vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .info a {
      text-decoration: none;
      color: white;
    }
  }
</style>