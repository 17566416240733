<template>
  <div class="content">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    
    <div class="wrapper">
      <video v-show="showVideo" autoplay muted playsinline @canplay="canPlay" :class="['showVideo', { 'hide': hide }]">
      <!-- <video v-show="showVideo" playsinline :class="['showVideo', { 'hide': hide }]"> -->
        <source src="../assets/video-tapel.mp4" type="video/mp4">
      </video>
      <img v-show="showPicture" src="../assets/video-foto.png" :class="['showPicture', { 'show': show }]">
      <div v-show="showSlogan" :class="['showSlogan', { 'show': show }]"> 
        <p> Nos asociamos a través del servicio, la innovación y la integridad. </p>
      </div>
    </div>

    <section>
      <OurCompany 
        :notButton = "true"
        vsTitle = "empresa"
        vsColor = "white"
      />
    </section>

    <section>
      <ParallaxSection
        sectionColor = "burgundy"
        sectionImg = "sostenibilidad-grande.jpg"
        sectionTitle = "sostenibilidad" 
        sectionText = "Nuestra principal tarea es ayudar a nuestros clientes a lograr el éxito, 
        integrándonos a su cadena de valor, mediante el desarrollo sostenible de procesos que estén 
        comprometidos con el cuidado del medio ambiente." 
        sectionButtonText= "Ver Más" 
        sectionLink = "sustainability"
        sectionVTitle = "sostenibilidad"
        sectionVColor = "burgundy"
      />
      <!-- <MobileSection
        sectionColor = "burgundy"
        sectionImg = "sostenibilidad2.jpg"
        sectionTitle = "sostenibilidad" 
        sectionText = "Nuestra principal tarea es ayudar a nuestros clientes a lograr el éxito, 
        integrándonos a su cadena de valor, mediante el desarrollo sostenible de procesos que estén 
        comprometidos con el cuidado del medio ambiente." 
        sectionButtonText= "Ver Más" 
        sectionLink = "sustainability"
        sectionVTitle = "sostenibilidad"
        sectionVColor = "burgundy"
      /> -->
    </section>

    <section>
      <div class="news-container">
        <p class="news-container-title"> NOTICIAS </p>
        
        <VerticalSubtitle 
          title = 'noticias'
          color = 'white'
        />
        
        <HomeNews
          newsImg = "news-25-a.jpg"
          newsTitle = "Nuevo sistema PolyPatch en Brasil"
          newsDate = "30/12/20"
        />
        <HomeNews
          newsImg = "news-26-a.jpg"
          newsTitle = "Soluciones para el control del Polvo"
          newsDate = "13/12/20"
        />
        <HomeNews
          newsImg = "news-27-a.jpg"
          newsTitle = "Donación a Séptima Compañía de Bomberos de Coronel"
          newsDate = "10/05/21"
        />
      </div>
    </section>

    <section>
      <ParallaxSection
        sectionImg = "servicios-grande.jpg"
        sectionTitle = "Servicios para tu empresa" 
        sectionText = "Tapel Willamette provee un servicio técnico 24/7 que 
        se caracteriza por tener personal especializado y calificado para realizar mantenciones 
        en los equipos en las diferentes plantas de nuestros clientes." 
        sectionButtonText= "Ver Más" 
        sectionLink = "services"
        sectionVTitle = "servicios"
        sectionVColor = "black"
      />
      <!-- <MobileSection
        sectionImg = "servicios-investigacion.jpg"
        sectionTitle = "Servicios para tu empresa" 
        sectionText = "Tapel Willamette provee un servicio técnico 24/7 que 
        se caracteriza por tener personal especializado y calificado para realizar mantenciones 
        en los equipos en las diferentes plantas de nuestros clientes." 
        sectionButtonText= "Ver Más" 
        sectionLink = "services"
        sectionVTitle = "servicios"
        sectionVColor = "black"
      /> -->
    </section>

    <section>
      <MobileProducts />
    </section>

    <section>
      
      <ParallaxSection
        sectionColor = "burgundy"
        sectionImg = "labs-grande.jpg"
        sectionTitle = "Laboratorio de I+D" 
        sectionText = "Tapel Willamette cuenta con un laboratorio de Investigación 
        y Desarrollo con tecnología de punta, integrado por profesionales capaces de crear 
        soluciones para un mercado moderno." 
        sectionButtonText = "Ver Más" 
        sectionLink = "services"
        sectionVTitle = "investigación"
        sectionVColor = "burgundy"
      />
      
      <!-- <MobileSection
        sectionColor = "burgundy"
        sectionImg = "labs.jpg"
        sectionTitle = "Laboratorio de I+D" 
        sectionText = "Tapel Willamette cuenta con un laboratorio de Investigación 
        y Desarrollo con tecnología de punta, integrado por profesionales capaces de crear 
        soluciones para un mercado moderno." 
        sectionButtonText = "Ver Más" 
        sectionLink = "services"
        sectionVTitle = "investigación"
        sectionVColor = "burgundy"
      /> -->
    </section>

    <section>
      <MobileContact />
    </section>

  </div>
</template>

<script>
// import MobileSection from '@/components/MobileSection.vue'
import ParallaxSection from '@/components/ParallaxSection.vue'
import MobileProducts from '@/components/MobileProducts.vue'
import MobileContact from '@/components/MobileContact.vue'
import HomeNews from '@/components/HomeNews.vue'
import OurCompany from '@/components/OurCompany.vue'
import VerticalSubtitle from '@/components/VerticalSubtitle.vue'

export default {
  name: 'Home',
  components: {
    ParallaxSection,
    // MobileSection,
    MobileProducts,
    MobileContact,
    HomeNews,
    OurCompany,
    VerticalSubtitle
  },
  data() {
    return {
      video: '',
      hide: false,
      show: false,
      showVideo: true,
      showPicture: false,
      showSlogan: false
    }
  },
  mounted () {
    // let video = document.querySelector('video');
    // video.muted = true;
    // video.play();
    // this.canPlay();
  },
  methods: {
    canPlay() {
      setTimeout(() => {
        this.hide = !this.hide
      }, 30000);

      setTimeout(() => {
        this.showVideo = !this.showVideo
        this.showPicture = !this.showPicture
        this.show = !this.show
      }, 32000);

      setTimeout(() => {
        this.showSlogan = !this.showSlogan
      }, 33000);

    }
  }
}
</script>

<style scoped>

.news-container {
  padding: 20px 20px 20px 20px;
  position: relative;
}
.news-container-title {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #831F3B;
}
.content {
  padding-top: 80px;
}
@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}
.wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
}
.showSlogan {
  font-family: 'Lato', sans-serif;
  font-style: italic;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.3);
  width: 100%;  
  padding: 4%;
  visibility: hidden;
}
.showSlogan.show {
  animation: fadeIn 3s;
  transition: visibility 3s;
  visibility: visible;
}
.wrapper video {
  max-width: 100%;
  max-height: 100%;
}
.wrapper img {
  max-width: 100%;
  max-height: 100%;
}
.showVideo {
  visibility: visible;
  /** */
  width: 100%;
  height: 60vh;
  object-fit: cover;
  object-position: 50% 0%;
}
.showVideo.hide {
  animation: fadeOut 3s;
  transition: visibility 3s;
  visibility: hidden;
}
.showPicture {
  visibility: hidden;
}
.showPicture.show {
  animation: fadeIn 3s;
  transition: visibility 3s;
  visibility: visible;
  /** */
  width: 100%;
  max-height: 60vh;
  object-fit: cover;
  object-position: 50% 0%;
}


@media screen and (max-width: 767px) {
  .wrapper .showSlogan {
    font-size: 1.1rem;
    font-weight: bold;
  }
  .showVideo {
    visibility: visible;
    /** */
    width: 100%;
    height: 100%;
    max-height: 60vh;
    object-fit: cover;
    object-position: 50% 0%;
  }
}


@media (min-width: 768px) and (max-width: 1199px) {
  .wrapper .showSlogan {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .showVideo {
    visibility: visible;
    /** TODO */
    /** */
    width: 100%;
    height: 100%;
    max-height: 60vh;
    object-fit: cover;
    object-position: 50% 0%;
  }
}


@media (min-width: 1200px) and (max-width: 1599px) {
  .wrapper .showSlogan {
    font-size: 1.6rem;
    font-weight: bold;
  }
  .news-container {
    position: relative; /** */
    display: flex;
    justify-content: space-between;
    padding: 0 15vw 0 15vw;
    margin: 20px 0 40px 0;
  }
  .news-container-title {
    display: none;
  }
}


@media screen and (min-width: 1600px) {
  .wrapper .showSlogan {
    font-size: 1.7rem;
    font-weight: bold;
  }
  .news-container {
    display: flex;
    justify-content: space-between;
    padding: 0 15vw 0 15vw;
    margin: 20px 0 40px 0;
  }
  .news-container-title {
    display: none;
  }
}


/* 
@media screen and (max-width: 767px) {}
@media (min-width: 768px) and (max-width: 1199px) {}
@media (min-width: 1200px) and (max-width: 1599px) {}
@media screen and (min-width: 1600px) {}
*/

</style>