<template>
  <div class="area-details-container">

    <OverlayImage
      overImageY = "0"
      overImageFolder = "areas"
      overImageImg = "AreaAmbiental.jpg"
      overImageTitle = "Área Ambiental"
    />

    <div v-for="ambiental in ambientals" :key="ambiental.title">
      <MobileArea 
        :maTitle = ambiental.title
        :maText = ambiental.text
        :maButtons = ambiental.buttons
      />  
    </div>

  </div>
</template>

<script>
import MobileArea from '@/components/MobileArea.vue'
import OverlayImage from '@/components/OverlayImage.vue'
export default {
  name: 'AreaDetails',
  components: {
    MobileArea,
    OverlayImage
  },
  data() {
    return {
      ambientals: []
    }
  },
  created() {
    this.ambientals = this.$store.state.ambientals
  }
}
</script>

<style scoped>
.area-details-container {
  margin-top: 80px;
}

</style>