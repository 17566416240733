<template>
  <div class="products">
    <VerticalSubtitle 
      title = 'productos'
      color = 'white'
    />
    
    <a href="https://polyquik.com/" target="_blank" rel="noopener noreferrer">
      <img class="products-img" src="../assets/products/prod1.png" alt="">
    </a>
    <a href="http://www.fastpatchsystems.com/" target="_blank" rel="noopener noreferrer">
      <img class="products-img" src="../assets/products/prod2.png" alt="">
    </a>
    <a href="https://wvcorailroad.com/" target="_blank" rel="noopener noreferrer">
      <img class="products-img" src="../assets/products/prod3.png" alt="">
    </a>

  </div>
</template>

<script>
import VerticalSubtitle from '@/components/VerticalSubtitle.vue'
export default {
  name: 'MobileProducts',
  components: {
    VerticalSubtitle
  }
}
</script>

<style scoped>
.products {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 20px 24px;
}
a {
  width: 30%;
  height: 30%;
}
a .products-img {
  width: 100%;
  height: 100%;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .products {
    padding: 20px 15vw 20px 15vw;
  }
}
@media screen and (min-width: 1600px) {
  .products {
    padding: 20px 15vw 20px 15vw;
  }
}
</style>