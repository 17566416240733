<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ==" crossorigin="anonymous" referrerpolicy="no-referrer" />
  
  <div class="contact-wrapper">
    
    <OverlayHeader 
      headerFolder = "company"
      headerImg = "slogan.jpg"
      headerTitle = "Contacto"
      headerText = "Tapel Willamette"  
    />

    <div class="wrapper-lg">
      <div class="wrapper-details">
        <div class="contact-title">
          Teléfono
        </div>
        <div class="contact-separator">
          <div class="cs1"></div>
          <div class="cs2"></div>
        </div>
        <div class="contact-text">
          <a href="tel:+56412928100">
            <i class="fas fa-phone-alt fa-lg"></i>
            +56 41 2928100
          </a>
        </div>

        <div class="contact-title">
          Email
        </div>
        <div class="contact-separator">
          <div class="cs1"></div>
          <div class="cs2"></div>
        </div>
        <div class="contact-text">
          <a href="mailto:ventas@tapel.cl">
            <i class="fas fa-envelope fa-lg"></i>
            ventas@tapel.cl
          </a>
        </div>
        
      </div>
      
      <div class="wrapper-map">
         <div class="contact-title">
          Ubicación
        </div>
        <div class="contact-separator">
          <div class="cs1"></div>
          <div class="cs2"></div>
        </div>
        <div class="map-wrapper">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2872.790454908698!2d-73.16462971396035!3d-36.97255441778071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9669c6615205be75%3A0x87f2eaafcf649b92!2sAv.%20Estero%20la%20Posada%203625%2C%20Coronel%2C%20B%C3%ADo%20B%C3%ADo!5e0!3m2!1ses-419!2scl!4v1636315984244!5m2!1ses-419!2scl" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
    
  
    <MobileContact />

  </div>
  
</template>

<script>
import MobileContact from '@/components/MobileContact.vue'
import OverlayHeader from '@/components/OverlayHeader.vue'
export default {
  name: 'Contact',
  components: {
    MobileContact,
    OverlayHeader
  }
}
</script>

<style scoped>
.contact-wrapper {
  margin-top: 80px;
}
.map-wrapper {
  padding: 0 20px 0 20px;
}
iframe {
  width: 100%;
  height: 80vw;
  border: 0;
}
.contact-title {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #831F3B;
  /* margin: 20px 0 20px 0; */
  margin: 30px 0 10px 0;
}
.contact-text {
  margin-bottom: 40px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
}
.contact-text a {
  text-decoration: none;
  color: black;
}
.contact-text i {
  margin-right: 14px;
}
/** */
  .contact-separator {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2px 0 20px 0;
  }
  .contact-separator .cs1 {
    border-bottom: 2px #831F3B solid;
    width: calc(50% - 20px);
  }
  .contact-separator .cs2 {
    border-top: 1px #9C9A9A solid;
    width: calc(50% - 20px);
  }
/** */
@media (min-width: 1200px) and (max-width: 1599px) {
  .contact-separator .cs1 {
    border-bottom: 2px #831F3B solid;
    width: 50%;
  }
  .contact-separator .cs2 {
    border-top: 1px #9C9A9A solid;
    width: 50%;
  }
  .contact-title {
    font-size: 20px;
  }
  .contact-text {
    font-size: 18px;
    margin-bottom: 50px;
  }
  .map-wrapper {
    padding: 0;
  }
  .wrapper-lg {
    display: flex;
    padding: 30px 15vw 30px 15vw;
  }
  .wrapper-map {
    order: 1;
    width: 65%;
  }
  .wrapper-details {
    order: 2;
    width: 32%;
    margin-left: 3%;
  }
  iframe {
    width: 100%;
    max-height: 50vh;
    border: 0;
  }
  
}
@media screen and (min-width: 1600px) {
  .contact-separator .cs1 {
    border-bottom: 2px #831F3B solid;
    width: 50%;
  }
  .contact-separator .cs2 {
    border-top: 1px #9C9A9A solid;
    width: 50%;
  }
  .contact-title {
    font-size: 22px;
  }
  .contact-text {
    font-size: 20px;
    margin-bottom: 70px;
  }
  .map-wrapper {
    padding: 0;
  }
  .wrapper-lg {
    display: flex;
    padding: 40px 15vw 40px 15vw;
  }
  .wrapper-map {
    order: 1;
    width: 65%;
  }
  .wrapper-details {
    order: 2;
    width: 32%;
    margin-left: 3%;
  }
  iframe {
    width: 100%;
    max-height: 50vh;
    border: 0;
  }
}
</style>