<template>

  <div class="section-container"
    :style="{'background-image': 'url(' + require(`../assets/sections/${sectionImg}`) + ')'}">
    <!-- "require(`../assets/sections/${sectionImg}`)"
    background-image: url("../assets/sections/laboratorio2.jpg"); -->

    <div class="section-overlay-triangle"
      :style="sectionColor?{'border-bottom': '400px solid rgba(131, 31, 59, 0.7)'}:{'border-bottom': '400px solid rgba(0, 0, 0, 0.7)'}"></div>
    
    <div class="section-overlay" 
      :style=" sectionColor ? {'background-color': 'rgba(131, 31, 59, 0.7)', 'color':'white'} : {'background-color': 'rgba(0, 0, 0, 0.7)', 'color':'white'}">
      
      <VerticalSubtitle 
        :title = sectionVTitle
        :color = sectionVColor
      />
      
      <div class="section-info">
        <p class="section-title"> {{sectionTitle}} </p>
        <p class="section-text"> {{sectionText}} </p>
        <button class="section-button" @click="$router.push(`${sectionLink}`)"
        :style=" sectionColor ? {'background-color': 'white', 'color':'#831F3B'} : {'background-color': 'white', 'color':'black'}">
          {{sectionButtonText}} 
          <i class="fas fa-angle-right fa-lg"></i>
        </button>
      </div>

    </div>
    
  </div>

</template>

<script>
import VerticalSubtitle from '@/components/VerticalSubtitle.vue'
export default {
  name: 'ParallaxSection',
  props: {
    sectionColor: String,
    sectionImg: String,
    sectionTitle: String,
    sectionText: String,
    sectionButtonText: String,
    sectionLink: String,
    sectionVTitle: String,
    sectionVColor: String
  },
  components: {
    VerticalSubtitle
  }
}
</script>

<style scoped>

.section-container {
  width: 100%;
  position: relative;
  height: 200px;
  /*  */
  /* background-image: url("../assets/sections/laboratorio2.jpg"); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section-container img {
  width: 100%;
  /* height: auto; */
  height: 200px;
  object-fit: cover;
}
.section-overlay {
  position: absolute;
  top: 0;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px 0 20px 0;
}
.section-button {
  background-color: white;
  color: #831F3B;
  text-transform: uppercase;
  padding: 8px 14px 8px 14px;
  width: 112px;
  border: none;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 14px;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-around;
  align-self: center;
}
.section-text {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: justify;
  padding: 10px 24px 10px 24px;
  /* display: none; */
}
.section-title {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}
/** */
.section-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-overlay-triangle {
  display: none;
}
@media (min-width: 1200px) and (max-width: 1599px) {

  .section-container {
    position: relative;
    overflow: hidden;
    height: 400px;
    /*  */
    /* background-image: url("../assets/sections/laboratorio2.jpg"); */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .section-container img {
    width: 100%;
    /* height: 50vh; */
    /* max-height: 400px; */
    height: 400px;
    
    object-fit: cover;
    object-position: 50% 50%;
    background-attachment: fixed;
  }
  
  .section-overlay {
    max-width: 33%;
    height: 400px;
  }
  .section-overlay-triangle {
    display: block;
    width: 0;
    height: 0;
    /* border-bottom: 33% solid rgba(131, 31, 59, 0.7); */
    border-right: 200px solid transparent;
    position: absolute;
    left: 33%;
    bottom:0;
  }
  .section-title {
    padding: 0;
    font-size: 20px;
    font-weight: 500;
    text-align: start;
    margin-bottom: 20px;
  }
  .section-text {
    padding: 0;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .section-info {
    padding: 0 0 0 15vw;
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;    
  }
  /** */
}
@media screen and (min-width: 1600px) {
  .section-container {
    position: relative;
    overflow: hidden;
    height: 400px;
    /*  */
    /* background-image: url("../assets/sections/laboratorio2.jpg"); */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .section-container img {
    width: 100%;
    /* height: 50vh; */
    /* max-height: 400px; */
    height: 400px;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .section-overlay {
    max-width: 33%;
    height: 400px;
  }
  .section-overlay-triangle {
    display: block;
    width: 0;
    height: 0;
    /* border-bottom: 330px solid rgba(131, 31, 59, 0.7); */
    border-right: 200px solid transparent;
    position: absolute;
    left: 33%;
    bottom:0;
  }
  .section-title {
    padding: 0;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .section-text {
    padding: 0;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .section-info {
    padding: 0 0 0 15vw;
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;    
  }

}
</style>