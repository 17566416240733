<template>

  <div class="news-header">
    <div class="news-header-title">
      Noticias Comunidad Tapel
    </div>
    <div class="news-header-separator"></div>
  </div>
  
  <div class="news-container" @click="$router.push(`/news/${headerNews[0].id}`)">
    <div class="news-carousel">
      <img :src="require(`../assets/news/${headerNews[0].image[0]}`)">
    </div>
    <div class="news-carousel-overlay">
      {{headerNews[0].title}}
    </div>
  </div>

  <!--  -->
  <div class="news-container-lg">
    <div class="principal-wrapper" @click="$router.push(`/news/${headerNews[0].id}`)">
      <div class="principal-news">
        <img :src="require(`../assets/news/${headerNews[0].image[0]}`)">
      </div>
      <div class="principal-news-overlay">
        {{headerNews[0].title}}
      </div>
    </div>
    <div class="secondary-wrapper">
      <div class="secondary-news" @click="$router.push(`/news/${headerNews[1].id}`)">
        <img :src="require(`../assets/news/${headerNews[1].image[0]}`)">
        <div class="secondary-news-overlay-top">
          {{headerNews[1].title}}
        </div>
      </div>
      <div class="secondary-news" @click="$router.push(`/news/${headerNews[2].id}`)">
        <img :src="require(`../assets/news/${headerNews[2].image[0]}`)">
        <div class="secondary-news-overlay-bottom">
          {{headerNews[2].title}}
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="lg-wrapper">
    <div class="lg-wrapper-right">
      <div class="news-article-title"> Categorías </div>
      <div class="news-article-separator">
        <div class="nas1"></div>
      </div>
      <div class="news-buttons">
        <button @click="$router.push(`/news/categories/recientes`)"> Recientes </button>
        <button @click="$router.push(`/news/categories/desarrollo`)"> Desarrollo </button>
        <button @click="$router.push(`/news/categories/proyectos`)"> Proyectos </button>
        <button @click="$router.push(`/news/categories/comunidad`)"> Comunidad </button>
        <button @click="$router.push(`/news/categories/comunicados`)"> Comunicados </button>
        <button @click="$router.push(`/news/categories/covid-19`)"> Covid-19 </button>
      </div>
    </div>
    <div class="lg-wrapper-separator"></div>
    <div class="lg-wrapper-left">
      <div class="news-article-title"> Otras Noticias </div>
      <div class="news-article-separator">
        <div class="nas1"></div>
      </div>
      <NewsMini />
    </div>
  </div>
  

  
  
</template>

<script>
import NewsMini from '@/components/NewsMini.vue'
export default {
  name: 'News',
  components: {
    NewsMini
  },
  data() {
    return {
      headerNews: []
    }
  },
  created() {
    this.headerNews = this.$store.getters.newsHeaders
  }
}
</script>

<style scoped>

.news-header {
  margin-top: 80px;
  background: #831F3B;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.news-header-title {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
}
.news-header-separator {
  margin-top: 2px;
  border-bottom: 1px solid white;
  width: calc(100% - 40px);
}

.news-container {
  position: relative;
}
.news-carousel img {
  width: 100%;
  height: auto;
}
.news-carousel-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
}
.news-article-title {
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-transform: uppercase;
  color: #831F3B;
  margin-top: 20px;
}
.news-article-separator {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2px 0 20px 0;
}
.news-article-separator .nas1 {
  border-bottom: 2px #9C9A9A solid;
  width: calc(100% - 40px);
}
.news-buttons {
  display: flex;
  flex-direction: column;
}
.news-buttons button {
  background: #831F3B;
  color: white;
  padding: 8px 10px 8px 10px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.news-container-lg {
  display: none;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .lg-wrapper {
    display: flex;
    padding: 0 15vw 3vw 15vw;
  }
  .lg-wrapper-left {
    width: 65%;
    order: 1;
  }
  .lg-wrapper-separator {
    width: 3%;
    order: 2;
  }
  .lg-wrapper-right {
    width: 32%;
    order: 3;
  }
  .news-container {
    display: none;
  }
  .news-container-lg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15vw 20px 15vw;
  }
  .principal-wrapper {
    width: 65%;
    position: relative;
    cursor: pointer;
  }
  .secondary-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32%;
    height: 34vw;
  }
  .principal-news {
    width: 100%;
    height: 34vw;
  }
  .secondary-news {
    width: 100%;
    height: 16vw;
    position: relative;
    cursor: pointer;
  }
  .principal-news img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .secondary-news img {
    width: 100%;
    /* height: auto; */
    max-height: 16vw;
    object-fit: cover;
    object-position: 50% 0%;
  }
  .principal-news-overlay {
    position: absolute;
    bottom: -2px; /** ?? */
    width: 100%;
    height: 25%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    padding: 0 4% 0 4%;
  }
  .secondary-news-overlay-top {
    position: absolute;
    bottom: -1px; /** ?? */
    width: 100%;
    height: 25%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    padding: 0 2% 0 2%;
  }
  .secondary-news-overlay-bottom {
    position: absolute;
    bottom: -1px; /** ?? */
    width: 100%;
    height: 25%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    padding: 0 2% 0 2%;
  }
  .news-header-title {
    font-weight: 500;
    font-size: 20px;
  }
  .news-header-separator {
    margin-top: 6px;
    border-bottom: 2px solid white;
    width: 70%;
  }
  .news-article-title {
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
  }
  .news-article-separator {
    margin: 4px 0 20px 0;
  }
  .news-article-separator .nas1 {
    border-bottom: 3px #9C9A9A solid;
    width: 70%;
  }
  .news-buttons {
    /* padding: 0 15vw 0 15vw; */
    padding: 0;
  }
  .news-buttons button {
    padding: 10px 12px 10px 12px;
    font-size: 16px;
    cursor: pointer;
  }
  .news-buttons button:hover {
    background: #9C9A9A;
    color: white;
  }
}
@media screen and (min-width: 1600px) {
  .lg-wrapper {
    display: flex;
    padding: 0 15vw 3vw 15vw;
  }
  .lg-wrapper-left {
    width: 65%;
    order: 1;
  }
  .lg-wrapper-separator {
    width: 3%;
    order: 2;
  }
  .lg-wrapper-right {
    width: 32%;
    order: 3;
  }
  .news-container {
    display: none;
  }
  .news-container-lg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15vw 20px 15vw;
  }
  .principal-wrapper {
    width: 65%;
    position: relative;
    cursor: pointer;
  }
  .secondary-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32%;
    height: 34vw;
  }
  .principal-news {
    width: 100%;
    height: 34vw;
  }
  .secondary-news {
    width: 100%;
    height: 16vw;
    position: relative;
    cursor: pointer;
  }
  .principal-news img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .secondary-news img {
    width: 100%;
    max-height: 16vw;
    object-fit: cover;
    object-position: 50% 0%;
  }
  .principal-news-overlay {
    position: absolute;
    bottom: -2px; /** ?? */
    width: 100%;
    height: 25%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    padding: 0 4% 0 4%;
  }
  .secondary-news-overlay-top {
    position: absolute;
    bottom: -1px; /** ?? */
    width: 100%;
    height: 25%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    padding: 0 2% 0 2%;
  }
  .secondary-news-overlay-bottom {
    position: absolute;
    bottom: -1px; /** ?? */
    width: 100%;
    height: 25%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    padding: 0 2% 0 2%;
  }
  /** */
  .news-header-title {
    font-weight: 600;
    font-size: 22px;
  }
  .news-header-separator {
    margin-top: 8px;
    border-bottom: 2px solid white;
    width: 70%;
  }
  .news-article-title {
    font-size: 22px;
    font-weight: 600;
    margin-top: 40px;
  }
  .news-article-separator {
    margin: 6px 0 30px 0;
  }
  .news-article-separator .nas1 {
    border-bottom: 3px #9C9A9A solid;
    width: 70%;
  }
  .news-buttons {
    /* padding: 0 15vw 0 15vw; */
    padding: 0;
  }
  .news-buttons button {
    padding: 12px 14px 12px 14px;
    font-size: 18px;
    cursor: pointer;
  }
  .news-buttons button:hover {
    background: #9C9A9A;
    color: white;
  }
}

</style>