<template>
  <div class="company-container">
    
    <div class="ourCompany">
      <VerticalSubtitle 
        :title = vsTitle
        :color = vsColor
      />
      <p class="ourCompany-title"> NUESTRA MISIÓN </p>
      <p class="ourCompany-text"> Invitamos al desafío y nos enfocamos en el proceso, 
      haciendo ciencia, avanzando en conocimiento, explorando nuevos terrenos y 
      acompañando a nuestros clientes en su crecimiento. </p>
      <button v-show="notButton" class="ourCompany-button" @click="$router.push('company')">
        Conócenos 
      </button>
    </div>

    <div class="horizontal-separator">
      <div class="hs1"></div>
      <div class="hs2"></div>
      <div class="hs1"></div>
    </div>

    <div class="vertical-separator">
      <div class="vs1"></div>
      <div class="vs2"></div>
    </div>

    <div class="ourCompany">
      <p class="ourCompany-title"> NUESTRA VISIÓN </p>
      <p class="ourCompany-text"> Tapel Willamette Inc. S.A.® Estará presente con 
      soluciones a las necesidades de sus clientes y lo acompañara con tecnología, 
      investigación y desarrollo para enfrentar los desafíos futuros.
      Seremos aliados estratégicos de nuestros clientes integrándonos a su cadena de valor. </p>
      <button v-show="notButton" class="ourCompany-button" @click="$router.push('company')">
        Visión 
      </button>
    </div>
  </div>
</template>

<script>
import VerticalSubtitle from '@/components/VerticalSubtitle.vue'
export default {
  name: 'OurCompany',
  props: {
    notButton: Boolean,
    vsTitle: String,
    vsColor: String
  },
  components: {
    VerticalSubtitle
  }
}
</script>

<style scoped>
.company-container {
  position: relative;
  display: flex;
  flex-direction: column;
}
/** separators */
.horizontal-separator {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hs1 {
  width: 20%;
  border-top: 1px solid #9C9A9A;
}
.hs2 {
  width: 20%;
  border-top: 3px solid #831F3B;
}
.vertical-separator {
  height: 216px;
  display: none;
}
.vs1 {
  height: 25%;
  width: 50%;
  border-right: 3px solid #831F3B;
}
.vs2 {
  height: 50%;
  width: calc(50% + 2px);
  border-right: 1px solid #9C9A9A;
}
/** text */
.ourCompany {
  margin: 20px 0 20px 0;
}
.ourCompany-text {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: justify;
  padding: 10px 24px 10px 24px;
}
.ourCompany-title {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  text-align: center;
}
.ourCompany-button {
  display: none;
}

@media screen and (max-width: 767px) {
  .company-container {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .company-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .ourCompany {
    width: 50%;
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    flex-direction: column;
  }
  .horizontal-separator {
    display: none;
  }
  .vertical-separator {
    display: block;
    position: relative;
  }
  .vs1 {
    margin: 0;
    position: absolute;
    top: 25%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .vs2 {
    margin: 0;
    position: absolute;
    top: 62.5%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  /** */
  .ourCompany-text {
    font-size: 15px;
  }
  .ourCompany-title {
    font-size: 19px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
.company-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 10px 15vw 10px 15vw; /*** */
  }
  .ourCompany {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .ourCompany-button {
    background-color: #831F3B;
    color: white;
    text-transform: uppercase;
    padding: 8px 14px 8px 14px;
    margin: 0 auto;
    border: none;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    /* font-size: 14px; */
    font-size: 16px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }
  .horizontal-separator {
    display: none;
  }
  .vertical-separator {
    display: block;
    position: relative;
    margin: 0 7.5vw 0 7.5vw; /*** */
  }
  .vs1 {
    margin: 0;
    position: absolute;
    top: 25%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .vs2 {
    margin: 0;
    position: absolute;
    top: 62.5%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  /** */
  .ourCompany-text {
    font-size: 16px;
    margin-bottom: 10px;
    padding: 0;
    /* padding: 10px 10vw 10px 10vw; */
  }
  .ourCompany-title {
    font-size: 20px;
    margin-bottom:10px
  }
}

@media screen and (min-width: 1600px) {
.company-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 10px 15vw 10px 15vw; /*** */
  }
  .ourCompany {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .ourCompany-button {
    background-color: #831F3B;
    color: white;
    text-transform: uppercase;
    padding: 8px 14px 8px 14px;
    margin: 0 auto;
    border: none;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    /* font-size: 14px; */
    font-size: 16px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }
  .horizontal-separator {
    display: none;
  }
  .vertical-separator {
    display: block;
    position: relative;
    margin: 0 7.5vw 0 7.5vw; /*** */
  }
  .vs1 {
    margin: 0;
    position: absolute;
    top: 25%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .vs2 {
    margin: 0;
    position: absolute;
    top: 62.5%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  /** */
  .ourCompany-text {
    font-size: 18px;
    margin-bottom:10px;
    padding: 0;
    /* padding: 10px 10vw 10px 10vw; */
  }
  .ourCompany-title {
    font-size: 22px;
    margin-bottom:10px
  }
}

</style>