<template>
  
  <div class="mobile-news-header">
      <div class="mobile-news-header-title">
        {{categorieTitle}}
      </div>
      <div class="mobile-news-header-separator"></div>
  </div>

  <div class="news-categories-container">
    
    <div class="news-minis">
      <div class="news-mini"
        v-for="fn in filteredNews" :key="fn.id"
        @click="$router.push(`/news/${fn.id}`)">
        <img :src="require(`../assets/news/${fn.image[0]}`)">
        <div class="news-mini-span">
          <span > {{fn.title}} </span>
        </div>
      </div>
    </div>
    
    <div class="news-vertical-separator"></div>
    
    <div class="news-categories">
      <div class="news-article-title"> Otras Categorías </div>
      <div class="news-article-separator">
        <div class="nas1"></div>
      </div>
      <div class="news-buttons">
        <button @click="$router.push(`/news/categories/recientes`)"> Recientes </button>
        <button @click="$router.push(`/news/categories/desarrollo`)"> Desarrollo </button>
        <button @click="$router.push(`/news/categories/proyectos`)"> Proyectos </button>
        <button @click="$router.push(`/news/categories/comunidad`)"> Comunidad </button>
        <button @click="$router.push(`/news/categories/comunicados`)"> Comunicados </button>
        <button @click="$router.push(`/news/categories/covid-19`)"> Covid-19 </button>
      </div>
    </div>
    

  </div>

</template>

<script>
export default {
  name: 'NewsCategorie',
  data() {
    return {
      filteredNews : [],
      categorieTitle: ''
    }
  },
  created() {
    this.getNews()
  },
  methods: {
    async getNews() {
      let data = this.$store.getters.news

      const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || "" //función auxiliar
      this.categorieTitle = capitalize(this.$route.params.categorie) //variable auxiliar
      
      //test
      if(this.categorieTitle == 'Recientes'){
        this.filteredNews = this.$store.getters.newsOthers
        return
      } else {
        data = data.filter( n => { //filtro de categoría
          for (let i=0;i<n.type.length;i++) {
            if (n.type[i] == this.categorieTitle){
              return n
            }
          } 
        })
        this.filteredNews = data
        // let dataAux = data
        // this.filteredNews = dataAux.slice(0,4)
      }
    }
  }
}
</script>

<style scoped>
.news-categories-container {
  padding: 0 20px 20px 20px;
}
.mobile-news-header {
  margin-top: 80px;
  background: #831F3B;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-news-header-title {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
}
.mobile-news-header-separator {
  margin-top: 2px;
  border-bottom: 1px solid white;
  width: calc(100% - 40px);
}
.news-mini {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  /* box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%); */
  box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 20%);
  cursor: pointer;
}
.news-mini img {
  width: 50%;
  height: auto;
}
.news-mini span {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 10px 0 10px;
}
/** */
.news-categories, .news-vertical-separator {
  display: none;
}
/** */
@media (min-width: 1200px) and (max-width: 1599px) {
  /** */
  .news-buttons {
    display: flex;
    flex-direction: column;
  }
  .news-buttons button {
    background: #831F3B;
    color: white;
    padding: 10px 12px 10px 12px;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .news-buttons button:hover {
    background: #9C9A9A;
    color: white;
  }
  .news-article-title {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: #831F3B;
    margin-top: 20px;
  }
  .news-article-separator {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2px 0 20px 0;
  }
  .news-article-separator .nas1 {
    border-bottom: 2px #9C9A9A solid;
    width: 100%;
  }
  .news-categories-container {
    padding: 0 15vw 30px 15vw;
    display: flex;
  }
  .news-minis {
    display: flex;
    flex-direction: column;
    width: 65%;
  }
  .news-categories {
    display: block;
    width: 32%;
  }
  .news-vertical-separator {
    display: block;
    width: 30px;
  } 
  /** */
  
  .mobile-news-header-title {
    font-weight: 500;
    font-size: 20px;
  }
  .mobile-news-header-separator {
    margin-top: 6px;
    border-bottom: 2px solid white;
    width: 70%;
  }
  .news-mini {
    justify-content: space-between;
  }
  .news-mini-span {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .news-mini img {
    width: 36%;
    max-height: 40vh;
    object-fit: cover;
    object-position: 50% 4%;
  }
  .news-mini span {
    font-size: 16px;
    padding: 0 30px 0 30px;
  }
}
@media screen and (min-width: 1600px) {
  /** */
  .news-buttons {
    display: flex;
    flex-direction: column;
  }
  .news-buttons button {
    background: #831F3B;
    color: white;
    padding: 12px 14px 12px 14px;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .news-article-title {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: #831F3B;
    margin-top: 20px;
  }
  .news-article-separator {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2px 0 20px 0;
  }
  .news-article-separator .nas1 {
    border-bottom: 2px #9C9A9A solid;
    width: 100%;
  }
  .news-categories-container {
    padding: 0 15vw 30px 15vw;
    display: flex;
  }
  .news-minis {
    display: flex;
    flex-direction: column;
    width: 65%;
  }
  .news-categories {
    display: block;
    width: 32%;
  }
  .news-vertical-separator {
    display: block;
    width: 30px;
  } 
  /** */
  .news-categories-container {
    padding: 0 15vw 50px 15vw;
  }
  .mobile-news-header-title {
    font-weight: 600;
    font-size: 22px;
  }
  .mobile-news-header-separator {
    margin-top: 8px;
    border-bottom: 2px solid white;
    width: 70%;
  }
  .news-mini {
    justify-content: space-between;
  }
  .news-mini-span {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .news-mini img {
    width: 36%;
    max-height: 40vh;
    object-fit: cover;
    object-position: 50% 4%;
  }
  .news-mini span {
    font-size: 18px;
    padding: 0 40px 0 30px;
  }
}
</style>