<template>
  <div class="overlayImage-container" @click="$router.push(`/${overImageFolder}/${overImageLink}`)">
    <img :src="require(`../assets/${overImageFolder}/${overImageImg}`)"
    :style="`object-position: 50% ${overImageY}%;`">
    <div class="overlayImage-overlay">
      <div class="overlayImage-title"> {{overImageTitle}} </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlayImage',
  props: {
    overImageFolder: String,
    overImageLink: String,
    overImageImg: String,
    overImageTitle: String,
    overImageY: {
      type: String,
      default: '50'
    }
  }
}
</script>

<style scoped>
.overlayImage-container {
  margin-top: 20px;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.overlayImage-container img {
  display: block;
  width: 100%;
  /* height: auto; */
  height: 33vw;
  object-fit: cover;
}
.overlayImage-overlay {
  position: absolute;
  bottom: 0;
  background-color: rgba(131, 31, 59, 0.8);
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlayImage-title {
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 500; /* 400 en diseño */
  text-transform: uppercase;
}

@media (min-width: 1200px) and (max-width: 1599px) {

  .overlayImage-container img {
    width: 100%;
    height: 33vh; /** TODO */
    object-fit: cover;
    object-position: 50% 0%;
  }
  .overlayImage-title {
    font-size: 20px;
    font-weight: 600;
  }
}
@media screen and (min-width: 1600px) {
  .overlayImage-container img {
    width: 100%;
    height: 33vh; /** TODO */
    object-fit: cover;
    object-position: 50% 0%;
  }
  .overlayImage-title {
    font-size: 22px;
    font-weight: 600;
  }
}

</style>