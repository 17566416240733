<template>
  <div class="upload-wrapper">
    <QuillEditor toolbar="#my-toolbar" v-model:content="content" contentType="html" style="width:75%">
    <template #toolbar>
      <div id="my-toolbar">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>

        <select class="ql-size">
          <option value="small"></option>
          <option selected></option> <!-- normal -->
          <option value="large"></option>
        </select>

        <button class="ql-blockquote"></button>

        <select class="ql-align">
          <option selected></option>
          <option value="center"></option>
          <option value="right"></option>
        </select>

        <select class="ql-color">
          <option selected></option>
          <option value="blue"></option>
          <option value="green"></option>
          <option value="red"></option>
        </select>

        <button class="ql-image"></button>
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-indent" value="-1"></button>
        <button class="ql-indent" value="+1"></button>

      </div>
      
      <button @click="getText"> GET TEXT </button>
      <button @click="login"> LOGIN </button>
      <button @click="getNews"> GET NEWS </button>

    </template>
  </QuillEditor>
    <button @click="$router.push(`/`)"> Ir a Home </button>
  </div>
  
</template>

<script>

export default {
  name: 'Upload',
  data () {
    return {
      content: ''
    }
  },
  methods: {
    async getNews() {
      await this.$store.dispatch('fetchNews')
      const test = this.$store.getters.test
      console.log('news:', test.title, test.text)
    },
    async login() {
      //TODO
      let credentials = {
        "email": "edison@magic-chile.cl",
        "password": "123qwerty"
      }
      await this.$store.dispatch('login', credentials)
      console.log('token:', this.$store.getters.token)
    },
    async getText() {
      console.log(this.content)
    }
  }
}

</script>

<style scoped>
  .carousel-wrapper {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .upload-wrapper {
    margin-top: 80px;
    height: calc(100vh - 380px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .upload-wrapper h2 {
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    font-size: 18px;
  }
  .upload-wrapper button {
    background: #831F3B;
    color: white;
    padding: 8px 10px 8px 10px;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    .upload-wrapper h2 {
      font-size: 22px;
    }
    .upload-wrapper button {
      padding: 10px 12px 10px 12px;
      font-size: 16px;
    }
  }
  @media screen and (min-width: 1600px) {
    .upload-wrapper h2 {
      font-size: 28px;
    }
    .upload-wrapper button {
      padding: 12px 14px 12px 14px;
      font-size: 20px;
    }
  }
</style>