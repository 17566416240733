import { createRouter, createWebHistory } from 'vue-router' // test
import Home from '../views/Home.vue'
import Company from '../views/Company.vue'
import Areas from '../views/Areas.vue'
import AreaMadera from '../views/AreaMadera.vue'
import AreaAmbiental from '../views/AreaAmbiental.vue'
import AreaIndustrial from '../views/AreaIndustrial.vue'
import Services from '../views/Services.vue'
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'
import NewsCategorie from '../views/NewsCategorie.vue'
import Contact from '../views/Contact.vue'
import Sustainability from '../views/Sustainability.vue'
import Links from '../views/Links.vue'
import Upload from '../views/Upload.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/company',
    name: 'Company',
    component: Company
  },
  {
    path: '/areas',
    name: 'Areas',
    component: Areas
  },
  {
    path: '/areas/woods',
    name: 'AreaMadera',
    component: AreaMadera
  },
  {
    path: '/areas/environmental',
    name: 'AreaAmbiental',
    component: AreaAmbiental
  },
  {
    path: '/areas/industrial',
    name: 'AreaIndustrial',
    component: AreaIndustrial
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/news/categories/:categorie',
    name: 'NewsCategorie',
    component: NewsCategorie
  },
  {
    path: '/news/:newsid',
    name: 'NewsDetail',
    component: NewsDetail
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/sustainability',
    name: 'Sustainability',
    component: Sustainability
  },
  {
    path: '/links',
    name: 'Links',
    component: Links
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload
  },
  { path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound 
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL), // test
  history: createWebHistory(), // test
  routes,
  scrollBehavior() {
    // always scroll to top and smooth
    return { top: 0, behavior: 'smooth' }
  }
})

export default router