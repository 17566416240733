<template>
  <div class="ma-container">
    
    <div class="ma-wrapper-left">
      <div class="ma-title"> {{maTitle}} </div>
      <div class="ma-separator"></div>
      <div class="ma-text">
        {{maText}}
      </div>
      <div class="ma-buttons" >
        <button v-for="(button, index) in maButtons" :key="index" @click="show(button)" :style="(index==0&&isFocus) ? {'background': '#831F3B'} : {}">
          {{button.title}} 
        </button>
      </div>
    </div>
    <div class="ma-wrapper-separator"></div>
    <div class="ma-wrapper-right">
      <div v-if="showDisplay"  class="ma-display">
        <img :src="require(`../assets/${activeButton.folder}/${activeButton.img}`)">
        <div class="ma-display-text">
          {{activeButton.text}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'MobileArea',
  props: {
    maTitle: String,
    maText: String,
    maButtons: Array
  },
  data() {
    return {
      isActive: false,
      showDisplay: false,
      activeButton: {},
      isFocus: false
    }
  },
  created() {
    this.show(this.maButtons[0])
    this.isFocus = true
  },
  methods: {
    show(b) {
      this.isFocus = false
      this.showDisplay = true
      this.activeButton = b
    }
  }
}
</script>

<style scoped>

.ma-container {
  /* margin: 30px 20px 20px 20px; WTF */
  padding: 30px 20px 20px 20px;
}
.ma-title {
  color: #831F3B;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
}
.ma-separator {
  border-bottom: 1px solid #C4C4C4;
  margin-bottom: 10px;
}
.ma-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  margin-bottom: 20px;
}
.ma-buttons {
  display: flex;
  flex-direction: column;
}

.ma-buttons button {
  background: #9A9A9A;
  color: white;
  padding: 8px 10px 8px 10px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-bottom: 2px;
}
/** orden importa! hover -> focus */
.ma-buttons button:hover {
  background: #757575;
}
.ma-buttons button:focus {
  background: #831F3B;
}
.ma-display {
  margin-top: 20px;
  width: 100%;
  position: relative;
}
.ma-display img {
  display: block;
  width: 100%;
  height: 60vw;
  object-fit: cover;
}
.ma-display-text {
  margin-top: 20px;
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .ma-container {
    padding: 30px 15vw 20px 15vw;
    display: flex;
  }
  .ma-wrapper-left, .ma-wrapper-right {
    width: 48%;
  }
  .ma-wrapper-separator {
    width: 4%;
  }
  .ma-title {
    font-size: 20px;
    font-weight: 500;
  }
  .ma-text {
    font-size: 16px;
  }
  .ma-display-text {
    font-size: 16px;
  }
  .ma-buttons button {
    padding: 8px 10px 8px 10px;
    font-size: 16px;
  }
  .ma-separator {
    margin-top: 6px;
    border-bottom: 2px solid #C4C4C4;
    margin-bottom: 20px;
  }
  .ma-display img {
    display: block;
    width: 100%;
    /* height: 40vw; */
    height: 30vw;
    object-fit: cover;
  }
}
@media screen and (min-width: 1600px) {
  .ma-container {
    padding: 30px 15vw 20px 15vw;
    display: flex;
  }
  .ma-wrapper-left, .ma-wrapper-right {
    width: 48%;
  }
  .ma-wrapper-separator {
    width: 4%;
  }
  .ma-title {
    font-size: 22px;
    font-weight: 500;
  }
  .ma-text {
    font-size: 18px;
  }
  .ma-display-text {
    font-size: 18px;
  }
  .ma-buttons button {
    padding: 8px 10px 8px 10px;
    font-size: 18px;
  }
  .ma-separator {
    margin-top: 8px;
    border-bottom: 2px solid #C4C4C4;
    margin-bottom: 20px;
  }
  .ma-display img {
    display: block;
    width: 100%;
    /* height: 40vw; */
    height: 30vw;
    object-fit: cover;
  }
}
</style>