<template>
  <div class="header-wrapper">
    <OverlayHeader 
      headerFolder = "areas"
      headerImg = "areas.jpg"
      headerTitle = "Áreas de Servicio"
      headerText = "Un mundo de soluciones para tu empresa, acompañado de los 
      mejores productos."
    />
  </div>
  
  <div class="areas-details">
    <OverlayImage
      overImageY = "0"
      overImageFolder = "areas"
      overImageLink = "woods"
      overImageImg = "area-maderas.jpg"
      overImageTitle = "Área Maderas"
    />
    <OverlayImage 
      overImageY = "80"
      overImageFolder = "areas"
      overImageLink = "environmental"
      overImageImg = "area-ambiental.jpg"
      overImageTitle = "Área Ambiental"
    />
    <OverlayImage
      overImageY = "4"
      overImageFolder = "areas"
      overImageLink = "industrial"
      overImageImg = "industrial.jpg"
      overImageTitle = "Área Industrial"
    />
  </div>
</template>

<script>
import OverlayHeader from '@/components/OverlayHeader.vue'
import OverlayImage from '@/components/OverlayImage.vue'

export default {
  name: 'Areas',
  components: {
    OverlayHeader,
    OverlayImage
  }
}
</script>

<style scoped>
.header-wrapper {
  margin-top: 80px;
}
.areas-unit {
  margin-top: 20px;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.areas-unit img {
  display: block;
  width: 100%;
  height: auto;
}
.areas-unit-overlay {
  position: absolute;
  bottom: 0;
  background-color: rgba(131, 31, 59, 0.7);
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.areas-unit-title {
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
}
.areas-container {
  margin-top: 80px;
}
.areas-main {
  width: 100%;
  position: relative;
}
.areas-details {
  padding: 10px 20px 30px 20px;
}
.areas-main img {
  display: block;
  width: 100%;
  height: auto;
}
.areas-overlay {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 20px 0;
}
.areas-main-title {
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.areas-main-text {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
  padding: 0 26px 0 26px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .areas-details {
    padding: 10px 15vw 30px 15vw;
  }
}
@media screen and (min-width: 1600px) {
  .areas-details {
    padding: 10px 15vw 30px 15vw;
  }
}
</style>