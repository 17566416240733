<template>
   <div class="news-others">
    <div class="news-mini" @click="$router.push(`/news/${latestNews[0].id}`)">
      <img :src="require(`../assets/news/${latestNews[0].image[0]}`)">
      <span > {{latestNews[0].title}} </span>
    </div>
    <div class="news-mini" @click="$router.push(`/news/${latestNews[1].id}`)">
      <img :src="require(`../assets/news/${latestNews[1].image[0]}`)">
      <span > {{latestNews[1].title}} </span>
    </div>
    <div class="news-mini" @click="$router.push(`/news/${latestNews[2].id}`)">
      <img :src="require(`../assets/news/${latestNews[2].image[0]}`)">
      <span > {{latestNews[2].title}} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsMini',
  data() {
    return {
      latestNews: []
    }
  },
  created() {
    if(this.$route.params.newsid) {
      this.latestNews = this.$store.getters.newsOthersWoId(this.$route.params.newsid)
    } else {
      // this.latestNews = this.$store.getters.newsOthers
      this.latestNews = this.$store.getters.newsHeadersOthers
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.news-others {
  padding: 0 20px 20px 20px;
}
.news-mini {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  /* box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%); */
  box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 20%);
  cursor: pointer;
}
.news-mini img {
  width: 50%;
  height: auto;
}
.news-mini span {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 10px 0 10px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .news-others {
    /* padding: 0 15vw 20px 15vw; */
    padding: 0;
  }
  .news-mini {
    justify-content: space-between;
  }
  .news-mini img {
    width: 36%;
    max-height: 40vh;
    object-fit: cover;
  }
  .news-mini span {
    font-size: 16px;
    padding: 0 30px 0 20px;
    text-align: center;
  }
}
@media screen and (min-width: 1600px) {
  .news-others {
    /* padding: 0 15vw 20px 15vw; */
    padding: 0;
  }
  .news-mini {
    justify-content: space-between;
  }
  .news-mini img {
    width: 40%;
    max-height: 40vh;
    object-fit: cover;
  }
  .news-mini span {
    font-size: 18px;
    padding: 0 50px 0 30px;
    text-align: center;
  }
}
</style>