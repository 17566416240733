<template>
  <div class="overLink-container">
    <a :href="`${overLink}`" target="_blank" rel="noopener noreferrer">
      <img :src="require(`../assets/${overLinkFolder}/${overLinkImg}`)"
        :style="`object-position: 50% ${overLinkImageY}%;`">
      <div class="overLink-overlay">
        <div class="overLink-title"> {{overLinkTitle}} </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'OverlayLink',
  props: {
    overLinkFolder: String,
    overLinkImg: String,
    overLinkTitle: String,
    overLink: String,
    overLinkImageY: {
      type: String,
      default: '50'
    }
  }
}
</script>

<style scoped>
.overLink-container {
  margin-top: 20px;
  width: 100%;
  position: relative;
  /* cursor: pointer; */
}
.overLink-container img {
  display: block;
  width: 100%;
  /* height: auto; */
  height: 33vw;
  object-fit: cover;
}
.overLink-overlay {
  position: absolute;
  bottom: 0;
  background-color: rgba(131, 31, 59, 0.8);
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overLink-title {
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 500; /* 400 en diseño */
  text-transform: uppercase;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .overLink-container {
    margin-bottom: 30px;
  }
  .overLink-container img {
    width: 100%;
    height: 25vh;
    object-fit: cover;
  }
  .overLink-title {
    font-size: 20px;
    font-weight: 600;
  }
}
@media screen and (min-width: 1600px) {
  .overLink-container {
    margin-bottom: 50px;
  }
  .overLink-container img {
    width: 100%;
    height: 25vh;
    object-fit: cover;
  }
  .overLink-title {
    font-size: 20px;
    font-weight: 600;
  }
}

</style>