<template>
  <div class="header-wrapper">
    <OverlayHeader 
    headerFolder = "company"
    headerImg = "Header.jpg"
    headerTitle = "Enlaces Externos"
    headerText = "Sustancias Peligrosas - Intranet"  
    />
  </div>
  <div class="links-details">
    <OverlayLink
      overLinkImageY = "0"
      overLinkFolder = "links"
      overLinkImg = "sisquim.jpg"
      overLinkTitle = "HDS"
      overLink = "http://tapel.sisquim.com/"
    />
    <div class="links-separator"></div>
    <OverlayLink
      overLinkImageY = "50"
      overLinkFolder = "links"
      overLinkImg = "qt9.jpg"
      overLinkTitle = "Intranet"
      overLink = "https://wilvaco.qt9app1.com/"
    />
  </div>
</template>

<script>
import OverlayHeader from '@/components/OverlayHeader.vue'
import OverlayLink from '@/components/OverlayLink.vue'

export default {
name: 'Links',
  components: {
    OverlayHeader,
    OverlayLink
  }
}
</script>

<style scoped>

.header-wrapper {
  margin-top: 80px;
}
.links-details {
  /** para mostrar el footer */
  height: calc(100vh - 540px);
  padding: 10px 20px 30px 20px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .links-details {
    padding: 30px 15vw 20px 15vw;
    display: flex;
    height: auto; /** */
  }
  .links-separator {
    width: 10%;
  }
}
@media screen and (min-width: 1600px) {
  .links-details {
    padding: 40px 15vw 20px 15vw;
    display: flex;
    height: auto; /** */
  }
  .links-separator {
    width: 10%;
  }
}

</style>