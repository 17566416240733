<template>
  <div class="header-wrapper">
    <OverlayHeader 
      headerFolder = "others"
      headerImg = "Equipo-2.jpg"
      headerTitle = "Sostenibilidad"
      headerText = ""
    />
  </div>
  <MobileArticle
    articleTitle = "POLÍTICA DE SOSTENIBILIDAD TAPEL WILLAMETTE INC. S.A."
    articleFolder = "company"
    articleImg = "LabCalidadCert.jpg"
    articleText = "Nuestra principal tarea es ayudar a nuestros clientes a lograr el éxito, 
    integrándonos a su cadena de valor, mediante el desarrollo sostenible de procesos que 
    estén comprometidos con las expectativas de nuestros accionistas, empleados, proveedores, 
    comunidad local y todos nuestros stakeholders, con un profundo compromiso por el cuidado 
    del medio ambiente."
    articleTextHl = "Nuestro compromiso con la sostenibilidad implica desarrollar y mantener 
    una cultura de hacer bien las cosas, donde exista un equilibrio entre el crecimiento 
    económico y el bienestar social con el mínimo impacto ambiental."
  />

  <div class="sus-intro">
    Nuestra política de sostenibilidad se basa en 5 ejes principales:
  </div>

  <div class="sus-container">
    
    <!-- <div class="sus-intro">
      Nuestra política de sostenibilidad se basa en 5 ejes principales:
    </div> -->
    
    <div class="sus-detail">
      <div class="sus-title">  
        Gobierno corporativo
      </div>
      <div class="sus-separator">
        <div class="ss1"></div>
        <div class="ss2"></div>
      </div>
      <div class="sus-text">
        Nuestro código de ética define el conjunto de principios y normas que regulan el 
        diseño, integración y funcionamiento de los Accionistas, Directorio y Alta 
        Administración con el resto de la organización.
      </div>
    </div>
    
    <div class="sus-detail-separator"></div>

    <div class="sus-detail">
      <div class="sus-title">  
        Compromiso con el Medio ambiente
      </div>
      <div class="sus-separator">
        <div class="ss1"></div>
        <div class="ss2"></div>
      </div>
      <div class="sus-text">
        Somos responsables de nuestro presente y futuro, entendiendo que cada acción que 
        realizamos hoy tiene una consecuencia y un impacto en el mañana. Es por eso que en 
        cada una de las actividades, procesos y proyectos en que participamos, consideramos 
        también los aspectos medio ambientales como parte de las variables de toma de decisión.
      </div>
    </div>
    
    <div class="sus-detail">
      <div class="sus-title">  
        Compromiso con nuestros trabajadores
      </div>
      <div class="sus-separator">
        <div class="ss1"></div>
        <div class="ss2"></div>
      </div>
      <div class="sus-text">
        El  trabajo en equipo y el respeto mutuo son las bases de nuestras interacciones 
        diarias. Promovemos el desarrollo integral de las personas potenciando sus 
        habilidades y destrezas mediante la gestión del conocimiento. Estamos profundamente 
        comprometidos con el cuidado de las personas mediante la creación de una cultura de 
        salud y seguridad ocupacional, implementando las mejores prácticas operacionales y 
        fortaleciendo un ambiente de trabajo limpio, confortable y seguro.
      </div>
    </div>

    <div class="sus-detail-separator"></div>

    <div class="sus-detail">
      <div class="sus-title">  
        Compromiso con nuestros clientes
      </div>
      <div class="sus-separator">
        <div class="ss1"></div>
        <div class="ss2"></div>
      </div>
      <div class="sus-text">
        Somos una compañía orientada al cliente, asociándonos con ellos a través de la 
        innovación y la tecnología para proveer soluciones a medida de sus necesidades y 
        que se integren a su cadena de valor. Nuestro objetivo es que nuestros clientes nos 
        vean como socios estratégicos para la consecución de sus objetivos comerciales en 
        las líneas de productos y servicios que atendemos. <br>
        “Nos asociamos a través del servicio, la innovación y la integridad"
      </div>
    </div>
    
    <div class="sus-detail">
      <div class="sus-title">  
        Compromiso con la comunidad
      </div>
      <div class="sus-separator">
        <div class="ss1"></div>
        <div class="ss2"></div>
      </div>
      <div class="sus-text">
        Escuchar, integrarse y trabajar con la comunidad para entender y considerar sus 
        preocupaciones y expectativas nos permite mantener relaciones cercanas con los 
        distintos actores sociales y vecinos, y de esta forma poder contribuir al desarrollo 
        local. Nos comprometemos a mejorar continuamente nuestros procesos con el objetivo 
        de identificar y controlar los riesgos que, potencialmente, podrían afectar el medio 
        ambiente y los bienes de la comunidad.
      </div>
    </div>
    
  </div>

</template>

<script>
import OverlayHeader from '@/components/OverlayHeader.vue'
import MobileArticle from '@/components/MobileArticle.vue'
export default {
  name: 'Sustainability',
  components: {
    OverlayHeader,
    MobileArticle
  }
}
</script>

<style scoped>
.header-wrapper {
  margin-top: 80px;
}
.sus-detail {
  margin: 30px 20px 40px 20px;
}
.sus-container span {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  margin-bottom: 20px;
}
.sus-title {
  color: #831F3B;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
}
.sus-separator {
  /* border-bottom: 1px solid #C4C4C4; */
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0 20px 0;
}
.sus-separator .ss1 {
  border-bottom: 2px #831F3B solid;
  width: 50%;
}
.sus-separator .ss2 {
  border-top: 1px #9C9A9A solid;
  width: 50%;
}
.sus-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  margin-bottom: 20px;
  margin-top: 30px;
}
.sus-intro {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  margin: 10px 0 20px 0;
  padding: 0 20px 0 20px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .sus-intro {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-align: justify;
    margin: 30px 0 10px 0;
    padding: 0 15vw 0 15vw;
  }
  .sus-detail-separator {
    width: 4%;
  }
  .sus-container {
    padding: 0 15vw 30px 15vw;
    display: flex;
    flex-wrap: wrap;
  }
  .sus-detail {
    margin: 20px 0 20px 0;
    padding-top: 10px;
    width: 48%;
  }
  .sus-title {
    font-size: 20px;
    font-weight: 500;
  }
  .sus-text {
    font-size: 16px;
    text-align: justify;
  }
}
@media screen and (min-width: 1600px) {
  .sus-intro {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    text-align: justify;
    margin: 30px 0 10px 0;
    padding: 0 15vw 0 15vw;
  }
  .sus-detail-separator {
    width: 4%;
  }
  .sus-container {
    padding: 0 15vw 30px 15vw;
    display: flex;
    flex-wrap: wrap;
  }
  .sus-detail {
    margin: 20px 0 20px 0;
    padding-top: 10px;
    width: 48%;
  }
  .sus-title {
    font-size: 22px;
    font-weight: 600;
  }
  .sus-text {
    font-size: 18px;
    text-align: justify;
  }
}
</style>