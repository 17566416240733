<template>
  <div class="menu">
    <div class="menu-wrapper">
      <div class="primary-links">
        <router-link to="/">
          <div class="logo"></div>
        </router-link>
        <router-link to="/company"> Empresa </router-link>
        <router-link to="/areas"> Áreas </router-link>
        <router-link to="/services"> Servicios </router-link>
        <router-link to="/news"> Noticias </router-link>
      </div>
      <div class="secondary-links">
        <router-link to="/links"> Enlaces </router-link>
        <router-link to="/contact"> Contacto </router-link>
        <div class="triangle"></div>
        <div class="square">
          <div :class="['menu-bars', { 'change': toggled }]" @click="showMobileNav">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div>
        </div>
      </div>
    </div>

    <div :class="['overlay', { 'show': toggled }]">
      <div class="overlay-content">
        <router-link class="overlay-link" to="/company" @click="showMobileNav"> Empresa </router-link>
        <router-link class="overlay-link" to="/areas" @click="showMobileNav"> Áreas </router-link>
        <router-link class="overlay-link" to="/services" @click="showMobileNav"> Servicios </router-link>
        <router-link class="overlay-link" to="/news" @click="showMobileNav"> Noticias </router-link>
        <router-link class="overlay-link" to="/links" @click="showMobileNav"> Enlaces </router-link>
        <router-link class="overlay-link" to="/contact" @click="showMobileNav"> Contacto </router-link>
      </div>
    </div>

  </div>
  
</template>

<script>
export default {
  data() {
    return {
      toggled: false
    }
  },
  methods: {
    showMobileNav() {
      this.toggled = !this.toggled;
    }
  }
}
</script>

<style scoped>

.menu {
  background: white;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}
.menu-wrapper {
  overflow: hidden;
  width: 92.7%;
  /* padding: 0 2% 0 2%; */
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu a {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  letter-spacing: 1px;
}
.menu a:not(:first-child) {
  margin-left: 40px;
}
.secondary-links a:last-child {
  display: none;
}
.primary-links {
  display: flex;
  align-items: center;
}
.secondary-links a {
  color: #831F3B;
}
.logo {
  margin-right: 30px;
  cursor: pointer;
}
/* .search-icon  {
  vertical-align: middle;
} */

/* bar menu init */
.menu-bars {
  position: fixed;
  z-index: 10;
  display: inline;
  cursor: pointer;
}
.bar1, .bar2, .bar3 {
  width: 30px;
  height: 3px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}
.change .bar2 {
  opacity: 0;
}
.change .bar3 {
  -webkit-transform: rotate(-45deg) translate(-7px, 7px);
  transform: rotate(45deg) translate(-7px, -7px);
}
/* bar menu end */

@media screen and (max-width: 767px) {

  .square { /* cuadrado */
    position: fixed;
    top: 0;
    right: 0;
    height: 80px;
    width: 80px;
    background: #831F3B;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .triangle { /* triangulo */
    position: fixed;
    top: 0;
    right: 80px;
    border-bottom: 80px solid #831F3B;
    border-left: 40px solid transparent;
  }
  .primary-links a:not(:first-child) {
    display: none;
  }
  .secondary-links a:not(:last-child) {
    display: none;
  }
  .secondary-links a:last-child {
    display: block;
  }
  .menu-wrapper {
    padding: 0 10px 0 10px;
    overflow: visible;
  }
  .primary-links .logo {  
    /* original logo:  
    width:264px;
    height: 75px; */
    width: 132px;
    height: 37.5px;
    background-image: url("../assets/TapelLogoPeque.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
/* overlay (background mobile menu) */
  .menu {
    z-index: 1;
  }
  .menu a {
    font-size: 1rem;
  }
  .menu a:not(:first-child) {
    margin-left: 0;
  }

  .overlay {
    position: fixed;
    top: 80px;
    width: 100vw;
    height: 0;
    background-color: #831F3B;
    transition: all 0.8s ease-in-out;
    transform: translateY(0);
  }
  .overlay.show {
    position: fixed;
    top: 80px;
    width: 100vw;
    /* height: 50vh; */
    height: 360px; /* alto total en todos los menus */
    transition: all 0.6s ease-in-out;
  }

  /** ** **/
  .overlay .overlay-content {
    visibility: hidden;
    opacity: 0;
  }
  .overlay.show .overlay-content {
    visibility: visible;
    opacity: 1;
  }
  /** ** **/

  .overlay .overlay-content .overlay-link {
    visibility: hidden;
    display: block;
    pointer-events: none; /** BUG LINKS **/
    color: white;
    opacity: 0;
    padding: 20px 0px 19px 0px;
    background-color: #831F3B;
  }
  .overlay .overlay-content .overlay-link:not(:last-child) {
    border-bottom: 1px solid #68182F;
  }
  .overlay.show .overlay-content .overlay-link {
    /** BUG LINKS **/
    visibility: visible;
    pointer-events: all; /** BUG LINKS **/
    background-color: #831F3B;
    opacity: 1;
  }
  .overlay.show .overlay-content .overlay-link:hover {
    background-color: #68182F;
    transition: background-color 0.0s;
  }

  .overlay.show .overlay-link:nth-child(1) {
    transition: all 0.5s 0.2s;
  }
  .overlay .overlay-link:nth-child(1) {
    transition: all 0.3s 0.5s;
  }

  .overlay.show .overlay-link:nth-child(2) {
    transition: all 0.5s 0.3s;
  }
  .overlay .overlay-link:nth-child(2) {
    transition: all 0.3s 0.4s;
  }
  .overlay.show .overlay-link:nth-child(3) {
    transition: all 0.5s 0.4s;
  }
  .overlay .overlay-link:nth-child(3) {
    transition: all 0.3s 0.3s;
  }
  .overlay.show .overlay-link:nth-child(4) {
    transition: all 0.5s 0.5s;
  }
  .overlay .overlay-link:nth-child(4) {
    transition: all 0.3s 0.2s;
  }
  .overlay.show .overlay-link:nth-child(5) {
    transition: all 0.5s 0.6s;
  }
  .overlay .overlay-link:nth-child(5) { 
    transition: all 0.3s 0.1s;
  }
  .overlay.show .overlay-link:nth-child(6) {
    transition: all 0.5s 0.7s;
  }
  .overlay .overlay-link:nth-child(6) {
    transition: all 0.3s 0.0s;
    
  }
}

/*********************************************************** */
@media (min-width: 768px) and (max-width: 1199px) {
  .menu {
    z-index: 1;
  }
  .menu a {
    /* font-size: 0.8rem; */
    font-size: min(1.6vw, 16px);
  }
  .menu a:not(:first-child) {    
    margin-left: 2vw;
  }
  .primary-links .logo {
    width: 164px;
    height: 78px;
    background-image: url("../assets/TapelLogoPeque.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .secondary-links a:nth-child(3) {
    display: none;
  }
  .overlay .overlay-content .overlay-link {
    display: none;
  }
  /*** */
  .primary-links a:not(:first-child):hover {
    color: #831F3B;
  }
  .secondary-links a:hover {
    color: black;
  }
}
/*********************************************************** */


/*  *******  */
@media screen and (min-width: 1200px) {
  .menu {
    z-index: 1;
  }
  .menu a {
    font-size: 1rem;
    /* font-size: min(1.2vw, 16px); */
  }
  .menu a:not(:first-child) {
    margin-left: 1.5rem;
  }
  .primary-links .logo {
    width: 280px;
    height: 50px;
    background-image: url("../assets/logo.png");
    background-repeat: no-repeat;
    background-position: center;
  }
  .overlay .overlay-content .overlay-link {
    display: none;
  }
  /*** */
  .primary-links a:not(:first-child):hover {
    color: #831F3B;
  }
  .secondary-links a:hover {
    color: black;
  }
}

</style>