<template>
  <div class="mobile-news-wrapper">
    
    <!-- <div class="mobile-news-header">
      <div class="mobile-news-header-title">
        {{mnHeaderTitle}}
      </div>
      <div class="mobile-news-header-separator"></div>
    </div> -->
    
    <div class="mobile-news-container">
      
      <!-- <div class="mobile-news-image">
        <img :src="require(`../assets/news/${mnImage}`)">
      </div> -->
      <div class="carousel-wrapper">
        <Carousel :imgSrc=mnImage />
      </div>

      <div class="mobile-news-title">
        {{mnTitle}}
      </div>
      <div class="mobile-news-subtitle">
        {{mnSubTitle}}
      </div>
      <div class="mobile-news-text">
        {{mnText}}
      </div>
      <div class="mobile-news-details">
        <div class="author"> {{mnDetailsAuthor}} </div>
        <div class="date"> {{mnDetailsDate}} </div>
      </div>
    </div>
    <div class="mobile-news-separator-wrapper">
      <div class="mobile-news-separator"> Relacionados </div>
    </div>
  </div>
  
</template>

<script>
import Carousel from '@/components/Carousel.vue'
export default {
  name: 'MobileNews',
  components: {
    Carousel
  },
  props: {
    // mnImage: String,
    mnImage: Array,
    mnTitle: String,
    mnSubTitle: String,
    mnText: String,
    mnDetailsAuthor: String,
    mnDetailsDate: String
  }
}
</script>

<style scoped>
/*  */
.carousel-wrapper {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
/*  */
.mobile-news-header {
  margin-top: 80px;
  background: #831F3B;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-news-header-title {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
}
.mobile-news-header-separator {
  margin-top: 2px;
  border-bottom: 1px solid white;
  width: calc(100% - 40px);
}
.mobile-news-container {
  padding: 30px 20px 30px 20px;
}
.mobile-news-image img {
  width: 100%;
  height: auto;
}
.mobile-news-title {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  text-align: justify;
  margin-top: 30px;
}
.mobile-news-subtitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
  color: #831F3B;
  margin-top: 20px;
}
.mobile-news-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  margin-top: 20px;
}
.mobile-news-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.mobile-news-details .author {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9A9A9A;
}
.mobile-news-details .date {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #831F3B;
}
.mobile-news-separator {
  background: #831F3B;
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  /* margin-bottom: 20px; */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-news-others {
  padding: 0 20px 20px 20px;
}
.mobile-news-mini {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  /* box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%); */
  box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 20%);
}
.mobile-news-mini img {
  width: 50%;
  height: auto;
}
.mobile-news-mini span {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  text-align: justify;
  padding: 0 10px 0 10px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  /* .carousel-wrapper {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  } */
  
  .mobile-news-wrapper {
    width: 100%;
  }
  .mobile-news-header-title {
    font-weight: 500;
    font-size: 20px;
  }
  .mobile-news-header-separator {
    margin-top: 6px;
    border-bottom: 2px solid white;
    width: 70%;
  }
  .mobile-news-container {
    /* padding: 20px 15vw 30px 15vw; */
    /* padding: 20px 0 30px 0; */
    padding: 0 0 30px 0;
  }
  .mobile-news-separator-wrapper {
    display: none;
    /* display: flex;
    justify-content: center; */
  }
  .mobile-news-separator {
    font-size: 16px;
    /* width: 70%; */
    width: 100%;
    height: 60px;
  }
  .mobile-news-title {
    font-size: 20px;
  }
  .mobile-news-subtitle {
    font-size: 16px;
  }
  .mobile-news-text {
    font-size: 16px;
  }
  .mobile-news-details .author {
    font-size: 16px;
  }
  .mobile-news-details .date {
    font-size: 16px;
  }
  .mobile-news-image img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    object-position: 50% 0%;
  }
}
@media screen and (min-width: 1600px) {
  .mobile-news-wrapper {
    width: 100%;
    margin-bottom: 20vh;
  }
  .mobile-news-header-title {
    font-weight: 600;
    font-size: 22px;
  }
  .mobile-news-header-separator {
    margin-top: 8px;
    border-bottom: 2px solid white;
    width: 70%;
  }
  .mobile-news-container {
    /* padding: 20px 15vw 30px 15vw; */
    /* padding: 20px 0 30px 0; */
    padding: 0 0 30px 0;
  }
  .mobile-news-separator-wrapper {
    display: none;
    /* display: flex;
    justify-content: center; */
  }
  .mobile-news-separator {
    font-size: 18px;
    width: 70%;
    height: 60px;
  }
  .mobile-news-title {
    font-size: 22px;
  }
  .mobile-news-subtitle {
    font-size: 18px;
  }
  .mobile-news-text {
    font-size: 18px;
  }
  .mobile-news-details .author {
    font-size: 18px;
  }
  .mobile-news-details .date {
    font-size: 18px;
  }
  .mobile-news-image img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    object-position: 50% 0%;
  }
}
</style>