<template>
  <div class="news">
    <div class="news-img-container">
      <img class="news-img" :src="require(`../assets/news/${newsImg}`)">
    </div>
    <div class="news-title"> {{newsTitle}} </div>
    <div class="news-text">
      <router-link to="/news"> Leer más </router-link>
      <div class="news-date"> {{newsDate}} </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileNews',
  props: {
    newsImg: String,
    newsTitle: String,
    newsDate: String
  }
}
</script>

<style scoped>
.news {
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
  margin-top: 20px;
  padding-bottom: 10px;
}
.news-title {
  width: 100%;
  padding: 0 15px 0 15px;
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 14px;
  margin-bottom: 18px;
}
.news-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px 0 15px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #831F3B;
}
.news-img {
  object-fit: cover;
  width: 100%;
  height: auto;
}
.news-text a {
  text-decoration: none;
  color: #831F3B;
  font-style: italic;
}



@media (min-width: 1200px) and (max-width: 1599px) {
  .news {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .news-img {
    width: 100%;
    height: 30vh;
    object-fit: cover;
  }
  .news-title { /** */
    padding: 0 10px 0 10px;
  }
}

@media screen and (min-width: 1600px) {
  .news {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .news-img {
    width: 100%;
    height: 30vh;
    object-fit: cover;
  }
}

</style>