<template>
   <div class="overlayText-container">
    <div class="overlayText">
      <img :src="require(`../assets/${overlayTextFolder}/${overlayTextImg}`)">
      <div class="overlayText-overlay"
      :style=" overlayTextColor ? {'background-color': 'rgba(131, 31, 59, 0.7)', 'color':'white'} : {'background-color': 'rgba(0, 0, 0, 0.6)', 'color':'white'}">
        <div v-if="overlayTextOne" class="overlayText-one"> {{overlayTextOne}} </div>
        <div v-if="overlayTextTwo" class="overlayText-two"> {{overlayTextTwo}} </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlayText',
  props: {
    overlayTextFolder: String,
    overlayTextImg: String,
    overlayTextColor: String,
    overlayTextOne: String,
    overlayTextTwo: String
  }
}
</script>

<style scoped>
.overlayText {
  width: 100%;
  position: relative;
}
.overlayText img {
  display: block;
  width: 100%;
  /* height: auto; */
  height: 50vw;
  object-fit: cover;
}
.overlayText-overlay {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px 0 20px 0;
}
.overlayText-one {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 0 26px 0 26px;
  margin-bottom: 10px;
}
.overlayText-two {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 0 26px 0 26px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .overlayText img {
    width: 100%;
    max-height: 40vh;
    object-fit: cover;
    object-position: 50% 30%;
  }
  .overlayText-two {
    font-size: 20px;
    font-weight: 600;
  }
}

@media screen and (min-width: 1600px) {
  .overlayText img {
    width: 100%;
    max-height: 50vh;
    object-fit: cover;
    object-position: 50% 28%;
  }
  .overlayText-two {
    font-size: 22px;
    font-weight: 700;
  }
}

</style>