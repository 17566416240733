<template>
  <div class="contact-container">
    <VerticalSubtitle 
      title = 'contacto'
      color = 'white'
    />
    <div class="contact-title">
      Ponte en Contacto
    </div>
    <div class="contact-text">
      Envíanos tu consulta y te responderemos a la brevedad.
    </div>
    
    <div class="form-separator">
      <div class="fs1"></div>
      <div class="fs2"></div>
    </div>

    <div class="contact-form">
      <form action="" @submit.prevent="sendEmail">
      <!-- <form action="" @submit.prevent="alert"> -->

        <div class="hwrapper">
          <div class="contact-input">
            <label for="fname"> Nombre </label>
            <input type="text" id="fname" name="firstname" v-model="data.user_firstname" required="required">
          </div>
          <div class="contact-input" id="lastname">
            <label for="flast"> Apellido </label>
            <input type="text" id="flast" name="lastname" v-model="data.user_lastname">
          </div>
        </div>
       
        <div class="hwrapper">
          <div class="contact-input">
            <label for="femail"> Correo Electrónico </label>
            <input type="text" id="femail" name="email" v-model="data.user_email" required="required">
          </div>
          <div class="contact-input">
            <label for="fphone"> Número Telefónico </label>
            <input type="text" id="fphone" name="phone" v-model="data.user_phone" required="required">
          </div>
        </div>

        <div class="contact-input">
          <label for="subject"> Mensaje </label>
          <textarea id="subject" name="subject" v-model="data.message" required="required"></textarea>
        </div>
        

        <!-- <button class="contact-button" @click="sendEmail"> -->
        <button class="contact-button">
          Enviar 
        </button>
        <!-- <input type="submit" value="Submit"> -->

      </form>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser'
import VerticalSubtitle from '@/components/VerticalSubtitle.vue'
export default {
  name: 'MobileContact',
  components: {
    VerticalSubtitle
  },
  data() {
    return {
      data: {
        user_name: '',
        user_firstname: '',
        user_lastname: '',
        user_email: '',
        user_phone: '',
        message: '',
        ticket: ''
      }
    }
  },
  methods: { //TODO: mejorar se hizo a la rápida
    sendEmail() {
      let d = new Date()
      let day = d.getDate().toString()
      let month = (d.getMonth() + 1).toString()
      let year = d.getFullYear().toString()
      let hours = d.getHours().toString()
      let minutes = d.getMinutes()>10?d.getMinutes().toString():'0'+d.getMinutes().toString()
      this.data.ticket = day + month + year + hours + minutes
      this.data.user_name = this.data.user_firstname + ' ' + this.data.user_lastname
      //
      emailjs.send('service_x7puvzm', 'template_huvzgax', this.data, '9N49al4jyO9zVilJu')
        .then((result) => {
            console.log('SUCCESS!', result.text)
            alert('Mensaje enviado exitosamente')
            this.data = {
              user_name: '',
              user_firstname: '',
              user_lastname: '',
              user_email: '',
              user_phone: '',
              message: '',
              ticket: ''
            }
        }, (error) => {
            console.log('FAILED...', error.text)
            alert('Hubo un problema al enviar tu mensaje')
        });
    }
  }
}
</script>

<style scoped>
.contact-container {
  position: relative;
  padding: 30px 20px 20px 20px;
}
.contact-form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
form .contact-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}
/* for spacing */
input {
  padding-left: 10px;
}
textarea {
  padding-left: 10px;
  padding-top: 10px;
}
.contact-button {
  background-color: #831F3B;
  color:white;
  text-transform: uppercase;
  padding: 8px 14px 8px 14px;
  width: 112px;
  border: none;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 14px;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  /* margin-top: 22px; */
}
.contact-title {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #831F3B;
  margin-bottom: 20px;
}
.contact-text {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
}
.contact-input label {
  align-self: flex-start;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.contact-input input {
  height: 40px;
  border: 1px solid #9C9A9A;
}
.contact-input textarea {
  height: 80px;
  border: 1px solid #9C9A9A;
}
.contact-input {
  margin-bottom: 20px;
}
/** */
.form-separator {
  display: none;
}
form .hwrapper {
  width: 100%;
}
#lastname {
  display: none;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .contact-container {
    padding: 30px 15vw 20px 15vw;
  }
  .contact-title {
    font-size: 20px;
    text-align: start;
  }
  .contact-text {
    font-size: 16px;
    text-align: start;
  }
  .contact-input label {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  /** */
  .contact-form form {
    background: #E5E5E5;
    display: flex;
    flex-direction: column;
    padding: 40px 30px 40px 30px;
  }
  .hwrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  #lastname {
    display: flex;
  }
  form .hwrapper .contact-input {
    width: calc(50% - 15px);
  }
  form .contact-input textarea {
    height: 120px;
  }
  .form-separator {
    display: block;
    width: 100%;
    display: flex;
    margin: 2px 0 20px 0;
  }
  .form-separator .fs1 {
    border-bottom: 2px #831F3B solid;
    width: 50%;
  }
  .form-separator .fs2 {
    border-top: 1px #9C9A9A solid;
    width: 50%;
  }
  
  /** */
}
@media screen and (min-width: 1600px) {
  .contact-container {
    padding: 30px 15vw 20px 15vw;
  }
  .contact-title {
    font-size: 22px;
    text-align: start;
  }
  .contact-text {
    font-size: 18px;
    text-align: start;
  }
  .contact-input label {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  .contact-form form {
    background: #E5E5E5;
    display: flex;
    flex-direction: column;
    padding: 40px 50px 40px 50px;
  }
  .hwrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  #lastname {
    display: flex;
  }
  form .hwrapper .contact-input {
    width: calc(50% - 25px);
  }
  form .contact-input textarea {
    height: 120px;
  }
  .form-separator {
    display: block;
    width: 100%;
    display: flex;
    margin: 2px 0 20px 0;
  }
  .form-separator .fs1 {
    border-bottom: 2px #831F3B solid;
    width: 50%;
  }
  .form-separator .fs2 {
    border-top: 1px #9C9A9A solid;
    width: 50%;
  }
  .contact-button {
    font-size: 16px;
    padding: 10px 16px 10px 16px;
    width: 120px;
  }
}

</style>