<template>
  <div class="notfound-wrapper">
    <h2> Página no encontrada </h2>
    <br><br>
    <button @click="$router.push(`/`)"> Ir a Home </button>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
  .notfound-wrapper {
    margin-top: 80px;
    height: calc(100vh - 380px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .notfound-wrapper h2 {
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    font-size: 18px;
  }
  .notfound-wrapper button {
    background: #831F3B;
    color: white;
    padding: 8px 10px 8px 10px;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    .notfound-wrapper h2 {
      font-size: 22px;
    }
    .notfound-wrapper button {
      padding: 10px 12px 10px 12px;
      font-size: 16px;
    }
  }
  @media screen and (min-width: 1600px) {
    .notfound-wrapper h2 {
      font-size: 28px;
    }
    .notfound-wrapper button {
      padding: 12px 14px 12px 14px;
      font-size: 20px;
    }
  }
</style>